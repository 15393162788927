import React from 'react';
import { useSelector } from 'react-redux';
import { Doughnut, defaults } from 'react-chartjs-2';
import { selectQueueInfo } from '../../../rdx/slices/queueSlice';
import _ from 'underscore';

const DoughnutChart = () => {
	const queueSlice = useSelector(selectQueueInfo, _.isEqual);
	const answeredCalls = queueSlice.reduce((acc, curr) => acc + curr.calls, 0);
	const missedCalls = queueSlice.reduce(
		(acc, curr) => acc + curr.missedCalls,
		0
	);

	// Global
	defaults.responsive = true;
	defaults.maintainAspectRatio = false;

	// Default
	defaults.color = '#95aac9';
	defaults.font.family = 'Cerebri Sans';
	defaults.font.size = 13;

	// Layout
	defaults.layout.padding = 0;

	// Legend
	defaults.plugins.legend.position = 'bottom';
	defaults.plugins.legend.labels.usePointStyle = true;
	defaults.plugins.legend.labels.padding = 16;

	// Point
	defaults.elements.point.radius = 0;
	defaults.elements.point.backgroundColor = '#6e84a3';

	// Line
	defaults.elements.line.tension = 0.4;
	defaults.elements.line.borderWidth = 3;
	defaults.elements.line.borderColor = '#6e84a3';
	defaults.elements.line.backgroundColor = 'transparent';
	defaults.elements.line.borderCapStyle = 'rounded';

	// Rectangle
	defaults.elements.bar.backgroundColor = '#6e84a3';
	defaults.elements.bar.borderColor = '#6e84a3';
	defaults.elements.bar.maxBarThickness = 10;

	// Arc
	defaults.elements.arc.backgroundColor = '#6e84a3';
	defaults.elements.arc.borderColor = '#fff';
	defaults.elements.arc.borderWidth = 4;
	defaults.elements.arc.hoverBorderColor = '#fff';

	// Tooltips
	defaults.plugins.tooltip.enabled = false;
	defaults.plugins.tooltip.mode = 'index';
	defaults.plugins.tooltip.intersect = false;
	defaults.plugins.tooltip.external = (context) => {
		let tooltip = document.getElementById('chart-tooltip');
		const chartType = context.chart.config._config.type;
		const model = context.tooltip;

		// Create tooltip if doesn't exist
		if (!tooltip) {
			tooltip = document.createElement('div');

			tooltip.setAttribute('id', 'chart-tooltip');
			tooltip.setAttribute('role', 'tooltip');
			tooltip.classList.add('popover');
			tooltip.classList.add('bs-popover-top');

			document.body.appendChild(tooltip);
		}

		// Hide tooltip if not visible
		if (model.opacity === 0) {
			tooltip.style.visibility = 'hidden';

			return;
		}

		if (model.body) {
			const title = model.title || [];
			const body = model.body.map(function (body) {
				return body.lines;
			});

			// Add arrow
			let content = '<div class="popover-arrow"></div>';

			// Add title
			title.forEach(function (title) {
				content +=
					'<h3 class="popover-header text-center">' + title + '</h3>';
			});

			// Add content
			body.forEach(function (body, i) {
				const colors = model.labelColors[i];
				const indicatorColor =
					chartType === 'line' &&
					colors.borderColor !== 'rgba(0,0,0,0.1)'
						? colors.borderColor
						: colors.backgroundColor;
				const indicator =
					'<span class="popover-body-indicator" style="background-color: ' +
					indicatorColor +
					'"></span>';
				const justifyContent =
					body.length > 1
						? 'justify-content-left'
						: 'justify-content-center';

				content +=
					'<div class="popover-body d-flex align-items-center ' +
					justifyContent +
					'">' +
					indicator +
					body +
					'</div>';
			});

			tooltip.innerHTML = content;
		}
		const canvas = context.chart.canvas;
		const canvasRect = canvas.getBoundingClientRect();

		const scrollTop =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop ||
			0;
		const scrollLeft =
			window.pageXOffset ||
			document.documentElement.scrollLeft ||
			document.body.scrollLeft ||
			0;

		const canvasTop = canvasRect.top + scrollTop;
		const canvasLeft = canvasRect.left + scrollLeft;

		const tooltipWidth = tooltip.offsetWidth;
		const tooltipHeight = tooltip.offsetHeight;

		const top = canvasTop + model.caretY - tooltipHeight - 16;
		const left = canvasLeft + model.caretX - tooltipWidth / 2;

		tooltip.style.top = top + 'px';
		tooltip.style.left = left + 'px';
		tooltip.style.visibility = 'visible';
	};

	defaults.scales.linear = {
		gridLines: {
			borderDash: [2],
			borderDashOffset: [2],
			color: '#e3ebf6',
			drawBorder: false,
			drawTicks: false,
			zeroLineColor: '#e3ebf6',
			zeroLineBorderDash: [2],
			zeroLineBorderDashOffset: [2],
		},
		ticks: {
			beginAtZero: true,
			padding: 10,
			stepSize: 10,
		},
	};

	defaults.scales.category = {
		gridLines: {
			drawBorder: false,
			drawOnChartArea: false,
			drawTicks: false,
		},
		ticks: {
			padding: 20,
		},
	};

	return (
		<div className='container-fluid tableContainer'>
			<div className='card' style={{ overflow: 'auto' }}>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col'>
							<h3 className='card-header-title'>
								Answered vs Missed Calls
							</h3>
						</div>
					</div>
				</div>
				<div className='card-body'>
					<div className="doughnut_wrapper">
					<Doughnut
						data={{
							labels: ['Answered Calls', 'Missed Calls'],
							datasets: [
								{
									label: 'Calls Breakdown',
									backgroundColor: ['#2C7BE5', '#A6C5F7'],
									data: [answeredCalls, missedCalls],
								},
							],
						}}
						options={{
							cutout: '83%',
							plugins: {
								legend: {
									position: 'bottom',
									labels: {
										usePointStyle: true,
									},
									legendCallback: function (chart) {
										const data = chart.data;
										let content = '';

										data.labels.forEach(function (
											label,
											index
										) {
											const bgColor =
												data.datasets[0]
													.backgroundColor[index];

											content +=
												'<span class="chart-legend-item">';
											content +=
												'<i class="chart-legend-indicator" style="background-color: ' +
												bgColor +
												'"></i>';
											content += label;
											content += '</span>';
										});

										return content;
									},
								},
								tooltip: {
									backgroundColor: 'white',
									bodyColor: 'black',
									titleColor: 'black',
									bodyAlign: 'center',
									yAlign: 'bottom',
									titleFont: {
										family: 'Cerebri Sans',
										weight: '900',
									},
									callbacks: {
										title: function (tooltipItem) {
											var label = tooltipItem[0].label;
											return label;
										},
										label: function (tooltipItem) {
											const value =
												tooltipItem.dataset.data[
													tooltipItem.dataIndex
												];
											return (
												'<span class="popover-body-value">' +
												value +
												'</span>'
											);
										},
									},
								},
							},
						}}
					/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DoughnutChart;
