
import { func, number, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import axios from '../../axios/axios'
import { useSnackbar } from "notistack";

const ChangePasswordPopUp = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        return () => {
            setForm({})
        }
    }, [])

    const [form, setForm] = useState({ "password": '', "userId": props.userId })

    const handleSubmit = () => {
        axios.post('/admin/users/change-password', form)
            .then((res) => {
                console.log(res)
                enqueueSnackbar("Password Updated!", {
                    variant: "success",
                    autoHideDuration: 3000
                })
            })
            .catch((err) => {
                enqueueSnackbar("Error! " + err, {
                    variant: "error",
                    autoHideDuration: 3000
                })
            })
    }

    return <div className="popup" >
        <div className="d-flex content card p-5" style={{ width: '50%', maxHeight: '80%', overflow: 'scroll' }}>
            <div className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle" style={{ height: "20px", width: "20px" }}
                onClick={() => props.closeCallback()}></div>

            <div className="row mt-2">
                <h3>Change Password</h3>
                <h4>{props.email}</h4>
            </div>
            <div className="row mt-2">
                <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon2"
                        onChange={(e) => setForm({ ...form, password: e.target.value })}
                    />
                </div>
                <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Confirm Password" aria-label="Confirm Password" aria-describedby="basic-addon2"
                        onChange={(e) => setForm({ ...form, confirmPassword: e.target.value })}
                    />
                </div>
                <div className="input-group mb-3">

                    <div className='btn-primary' onClick={() => {
                        handleSubmit();
                    }}>Submit</div>

                </div>
            </div>
        </div>
    </div>


}

ChangePasswordPopUp.propTypes = {
    closeCallback: func,
    userId: number,
    email:string
}

export default ChangePasswordPopUp;