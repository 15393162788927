import React, { useEffect, useState } from "react";
import StatsPage from "../components/common/StatsPage";
import { Card, CardBody, CardTitle, Button } from "react-bootstrap";
import axios from "../axios/axios";
import { useSelector } from "react-redux";
import { selectSelectedCustomer } from "../rdx/slices/customerSlice";
import { useSnackbar } from "notistack";

const RecordingsPage = () => {
    const [callId, setCallId] = useState("");
    const [callDetails, setCallDetails] = useState();
    const selectedCustomer = useSelector(selectSelectedCustomer);
    const [loading, setLoading] = React.useState(false);
    const [audioState, setAudioState] = React.useState({
        sound: null,
        state: "STOPPED",
    });

    const { enqueueSnackbar } = useSnackbar();

    const audioPlayer = document.getElementById("WavPlayer");

    const handleCallIdChange = (value) => {
        console.log(value);
        setCallId(value);
    };

    console.log(loading);

    useEffect(() => {
        const listenerPlaying = audioPlayer.addEventListener("playing", (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "PLAYING" });
        });

        const listenerEnded = audioPlayer.addEventListener("ended", (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "STOPPED" });
        });

        const listenerPaused = audioPlayer.addEventListener("pause", (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "STOPPED" });
        });

        const onTimeUpdate = audioPlayer.addEventListener("timeupdate", (e) => {
            console.log(e.target.value);
        });

        return () => {
            audioPlayer.removeEventListener("playing", listenerPlaying);
            audioPlayer.removeEventListener("ended", listenerEnded);
            audioPlayer.removeEventListener("pause", listenerPaused);
            audioPlayer.removeEventListener(onTimeUpdate);
        };
    }, []);

    function playRecording() {
        const audioElement = document.getElementById("WavPlayer");

        if (audioElement.getAttribute("sound") === callId) {
            audioElement.play();
        } else {
            setLoading(true);
            axios
                .get(
                    `/call-details/recording?callId=${callId}&customerId=${selectedCustomer.id}`,
                    {
                        withCredentials: true,
                        responseType: "blob",
                    }
                )
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.setAttribute("sound", callId);
                    link.href = url;
                    document
                        .getElementById("WavPlayer")
                        .setAttribute("sound", callId);
                    document
                        .getElementById("WavPlayer")
                        .setAttribute("src", url);
                    document.getElementById("WavPlayer").play();
                })
                .catch((err) => {
                    alert(JSON.stringify(err));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    function detailsCard(details) {
        return (
            <Card className="w-50 pt-50">
                <CardTitle className="px-4 pt-3">
                    <h3 style={{ fontWeight: "bolder" }}>Call Details</h3>
                </CardTitle>
                <CardBody>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <h4 className="p-0 my-0 me-3">MSIDSN:</h4>
                        <div>
                            {details.msisdn === "" ? "ANON" : details.msisdn}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <h4 className="p-0 my-0 me-3">DDI:</h4>
                        <div>{details.ddi === "" ? "" : details.ddi}</div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <h4 className="p-0 my-0 me-3">Start Time</h4>
                        <div>
                            {new Date(
                                details.startTimeUnix * 1000
                            ).toLocaleString()}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <h4 className="p-0 my-0 me-3">End Time:</h4>
                        <div>
                            {new Date(
                                details.endTimeUnix * 1000
                            ).toLocaleString()}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <h4 className="p-0 my-0 me-3">Call ID:</h4>
                        <div>{details.callId}</div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <h4 className="p-0 my-0 me-3">Call Duration:</h4>
                        <div>
                            {Math.floor(details.duration / 60)}m :{" "}
                            {Math.round(details.duration % 60)}s
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <h4 className="p-0 my-0 me-3">Call Status:</h4>
                        <div>{details.status}</div>
                    </div>

                    {details.hasRecording ? (
                        <div>
                            <CardTitle className="pt-3 mb-0">
                                <h3 style={{ fontWeight: "bolder" }}>
                                    Call Recording
                                </h3>
                            </CardTitle>
                            <CardBody className="mt-2">
                                <Button
                                    variant={
                                        audioState.state === "STOPPED"
                                            ? "success"
                                            : "danger"
                                    }
                                    onClick={() => {
                                        if (audioState.state === "STOPPED") {
                                            playRecording();
                                        } else {
                                            audioPlayer.pause();
                                        }
                                        setAudioState(
                                            audioState.state == "PLAYING"
                                                ? "STOPPED"
                                                : "PLAYING"
                                        );
                                    }}
                                >
                                    {audioState.state === "STOPPED"
                                        ? "PLAY"
                                        : "PAUSE"}
                                </Button>

                                <Button className="ms-3"
                                    variant="info"
                                    onClick={() => {
                                        axios
                                            .get(
                                                `/call-details/recording?callId=${callId}&customerId=${selectedCustomer.id}`,
                                                {
                                                    withCredentials: true,
                                                    responseType: "blob",
                                                }
                                            )
                                            .then((response) => {
                                                const url =
                                                    window.URL.createObjectURL(
                                                        new Blob([
                                                            response.data,
                                                        ])
                                                    );
                                                const link =
                                                    document.createElement("a");
                                                link.href = url;
                                                link.setAttribute(
                                                    "download",
                                                    `recording_${callId}.wav`
                                                );
                                                document.body.appendChild(link);
                                                link.click();
                                            })
                                            .catch((err) => {
                                                alert(JSON.stringify(err));
                                            });
                                    }}
                                >
                                    DOWNLOAD
                                </Button>
                            </CardBody>
                        </div>
                    ) : (
                        ""
                    )}
                </CardBody>
            </Card>
        );
    }

    const doSearch = () => {
        axios
            .get(
                `/call-details?customerId=${selectedCustomer.id}&callId=${callId}`
            )
            .then((r) => setCallDetails(r.data))
            .catch((err) => {
                alert(err);
                if (err.response.status == 400) {
                    console.log(err.response.data);
                    enqueueSnackbar(err.response.data.body, {
                        variant: "error",
                    });
                } else if (err.response.status > 500) {
                    console.log("ERROR");
                }
            });
    };
    const render = (
        <div className="px-5">
            <Card className="w-25">
                <CardTitle className="px-4 pt-3">
                    <h3 style={{ fontWeight: "bolder" }}>Search By Call ID</h3>
                </CardTitle>
                <CardBody className="d-flex flex-column pt-0">
                    <input
                        onChange={(e) => handleCallIdChange(e.target.value)}
                        type="text"
                        className="mb-2"
                        placeholder="Enter the Call ID"
                        value={callId}
                    ></input>
                    <Button
                        onClick={() => doSearch()}
                        className="p-1"
                        variant="success"
                    >
                        Search
                    </Button>
                </CardBody>
            </Card>
            {callDetails ? detailsCard(callDetails) : ""}
        </div>
    );
    return (
        <StatsPage
            title="Call Recording"
            pretitle="Recordings"
            render={render}
        ></StatsPage>
    );
};

export default RecordingsPage;
