import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";
import axios from "../../axios/axios";
import { selectSelectedCompBrand, selectSelections } from "../../rdx/slices/compSlice";
import Card from "../home/cards/Card";

const CompetitionStats = () => {
    const [data, setData] = React.useState();

    const selections = useSelector(selectSelections, _.isEqual);
    const selectedBrand = useSelector(selectSelectedCompBrand);

    useEffect(() => {
    
        if (selections.client && selections.competition) {
            axios
                .get(
                    `/competitions/brand/${selectedBrand.id}/client/${
                        selections.client ? selections.client.clientId : ""
                    }/window/${
                        selections.competition
                            ? selections.competition.competitionWindowId
                            : ""
                    }`
                )
                .then((ok) => {
                    setData(ok.data.response);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [selections]);

    const uniqueCustomers = (
        <Card
            title="Unique Customers"
            tag="customers"
            icon="volume-1"
            text={data ? data.uniqueCustomers : "..."}
        />
    );
    const totalEntries = (
        <Card
            title="Total Entries"
            tag="entries"
            icon="volume-1"
            text={data ? data.totalEntries : "..."}
        />
    );
    const totalConsumerSpend = (
        <Card
            title="Total Consumer Spend"
            tag="spend"
            icon=""
            text={
                data
                    ? "£" + (data.totalConsumerSpend / 100.0).toFixed(2)
                    : "..."
            }
        />
    );
    const averageCustomerSpend = (
        <Card
            title="Average Spend"
            tag="per customer"
            icon=""
            text={
                data
                    ? "£" + (data.averageSpendPerCustomer / 100.0).toFixed(2)
                    : "..."
            }
        />
    );
    const averageChargesPerCustomer = (
        <Card
            title="Average Charges"
            tag="per customer"
            icon=""
            text={data ? data.averageChargesPerCustomer.toFixed(2) : "..."}
        />
    );
    const averageFreeEntriesPerCustomer = (
        <Card
            title="Average Free Entries"
            tag="per customer"
            icon=""
            text={data ? data.averageFreeEntriesPerCustomer.toFixed(2) : "..."}
        />
    );

    return (
        <div className="row p-3">
            {uniqueCustomers}
            {totalEntries}
            {totalConsumerSpend}
            {averageCustomerSpend}
            {averageChargesPerCustomer}
            {averageFreeEntriesPerCustomer}
        </div>
    );
};

export default CompetitionStats;
