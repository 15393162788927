import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { sortByObjectProperty } from '../../utils/sortUtils';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompBrands, selectSelectedCompBrand, setSelectedBrand } from '../../rdx/slices/compSlice';

const CompetitionBrandSelector = () => {

	const brands = useSelector(selectCompBrands)
	const selectedBrand = useSelector(selectSelectedCompBrand)
	const dispatch = useDispatch()
	const options = [];

	useEffect(() => {

	}, [])


	const formatOption = (brand) => {
		return (
			<option
				key={brand.clientId}
				value={brand.clientId}
				id={brand.clientId}
			>
				{brand.name}
			</option>
		);
	};

	const copy = [...brands?brands:[]];
	sortByObjectProperty("name", "ASC", copy)

	copy.forEach((brand) => {
		options.push(formatOption(brand))
	})

	const handleSelection = (event) => {

		if (event.target.value !== null) {
			dispatch(setSelectedBrand(event.target.value))
		}

	}


	return (
		<Form>
			<Form.Group controlId='selecty'>
				{/* <Form.Label>Viewing</Form.Label> */}
				<Form.Control
					as='select'
					onChange={(event) =>
						handleSelection(event)
					}
					value={selectedBrand ? selectedBrand.brandId : 0}
				>
					{options}
				</Form.Control>
			</Form.Group>
		</Form>
	);


};


export default CompetitionBrandSelector;
