import React from 'react';
import { PropTypes } from 'prop-types';
import AgentLabel from './AgentLabel';
import { formatSecondsToTime } from '../../../utils/formatUtils';
const QueueInfoTableRow = (props) => {
	const statusIconFormat = (status) => {
		let className = '';
		switch (status) {
			case 'Offline':
				className = 'badge badge-pill bg-danger-soft';
				break;
			case 'Busy':
				className = 'badge badge-pill bg-warning-soft';
				break;
			case 'Available':
				className = 'badge badge-pill bg-success-soft';
		}
		return <span className={className}>{status.toUpperCase()}</span>;
	};

	return (
		<tr>
			<td><AgentLabel agent={props.data.agentName} />{props.data.agentName}</td>
			<td>{props.data.directLine}</td>
			<td>{statusIconFormat(props.data.status)}</td>
			<td>{props.data.calls}</td>
			<td>{props.data.missedCalls}</td>
			<td>{formatSecondsToTime(props.data.totalTalkTime)}</td>
			<td>{formatSecondsToTime(props.data.averageTalkTime)}</td>
		</tr>
	);
};

QueueInfoTableRow.propTypes = { data: PropTypes.object.isRequired };

export default QueueInfoTableRow;
