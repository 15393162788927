import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./rdx/store";
import reportWebVitals from "./reportWebVitals";
import {SnackbarProvider} from 'notistack'

ReactDOM.render(
 
    <Provider store={store}>
    <SnackbarProvider>
      <App />
      </SnackbarProvider>
    </Provider>,
 
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
