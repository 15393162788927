import { DateTime } from 'luxon';
import React from 'react'
import PropTypes from 'prop-types'

import "flatpickr/dist/themes/material_green.css";
import axios from '../../../axios/axios';
import { useSelector } from 'react-redux';
import { selectSelectedCustomer } from '../../../rdx/slices/customerSlice';
import { useSnackbar } from 'notistack'

const UploadSTPopUp = (props) => {

    const [form, SetForm] = React.useState({ "soundId": null, "content": null, "liveFrom": DateTime.now().setZone("Europe/London").toFormat("dd-MM-yyyy HH:mm:ss") })
    const [loading,setLoading] = React.useState(false);

    const customer = useSelector(selectSelectedCustomer);

    const {enqueueSnackbar} = useSnackbar();

    function handleSubmit(){
        setLoading(true)

        axios.post(`/sounds/customer/${customer.id}/sound-task/create`,form)
        .then(()=>{
            props.onCloseClicked()
            enqueueSnackbar("New Sound Task Uploaded.", { variant: "success", autoHideDuration: 3000 })
        }).catch((err)=>{
            enqueueSnackbar(err.response?err.response.data.split(":").join("\n\n"):err.status, { variant: "error", autoHideDuration: 5000 })    
        }).finally(()=>{
            setLoading(false)
        })
    }

    return (
        <div className="popup">
            <div className="d-flex content card align-items-center justify-content-around p-5">
                <div className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle" style={{ height: "20px", width: "20px" }}
                    onClick={() => props.onCloseClicked()}></div>

                <div className="row mt-2">
                    <h3>Upload a New Sound Task</h3>
                </div>

                

                <div className="row mt-2">

                    <div className="input-group mb-3">
                        <input type="number" className="form-control" placeholder="Sound ID" aria-label="Recipient's username" aria-describedby="basic-addon2"
                        onChange={(e) => SetForm({ ...form, soundId: e.target.value })}
                         />
                    </div>

                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Content</span>
                        </div>
                        <textarea className="form-control" aria-label="With textarea" onChange={(e) => SetForm({ ...form, content: e.target.value })}>{form.content}</textarea>
                    </div>

                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Live Time</span>
                        </div>
                        <input type="text" className="form-control" placeholder={form.liveFrom} 
                        aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => SetForm({ ...form, liveFrom: e.target.value })}/>
                    </div>

                  
                </div>
                
                {loading?<div className="spinner-border text-primary"/>:<div className="p-2 btn-success rounded" onClick={()=> !loading?handleSubmit():{}}>Submit</div>}
                
            </div>



        </div >
    );
}

UploadSTPopUp.propTypes = {
    onCloseClicked: PropTypes.func.isRequired
}

export default UploadSTPopUp;