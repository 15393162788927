
import { func } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from '../../axios/axios'
import { selectUsersCustomers } from '../../rdx/slices/customerSlice'
import { useSnackbar } from "notistack";

const CreateUserPopUp = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    const [permissions, setPermissions] = useState([])
    const [assignedPermissions, setAssignedPermissions] = useState([])
    const [assignedCustomers, setAssignedCustomers] = useState([])
    const customersSelect = useSelector(selectUsersCustomers);
    const [customers, setCustomers] = useState(Array.from(customersSelect));

    useEffect(() => {

        axios.get("/admin/permissions")
            .then((resp) => {
                console.log(resp)
                setPermissions(resp.data)
            })

        return () => {
            setPermissions([]);
            setAssignedPermissions([])
        }
    }, [])

    const [form, SetForm] = React.useState({ "email": '', "permissions": [], password: '' })

    const handleSubmit = () => {
        form.permissions = assignedPermissions;
        form.customers = assignedCustomers.map(it => it.id);
        form.role = 'USER';
        axios.post('/admin/users/create', form)
            .then((res) => {
                console.log(res)
                enqueueSnackbar("User Created!", {
                    variant: "success",
                    autoHideDuration: 3000
                })
            })
            .catch((err) => {
                enqueueSnackbar("Error! " + err, {
                    variant: "error",
                    autoHideDuration: 3000
                })
            })
    }

    return <div className="popup" >
        <div className="d-flex content card p-5" style={{ width: '50%', maxHeight: '80%', overflow: 'scroll' }}>
            <div className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle" style={{ height: "20px", width: "20px" }}
                onClick={() => props.closeCallback()}></div>

            <div className="row mt-2">
                <h3>Create New User</h3>
            </div>
            <div className="row mt-2">
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="First Name" aria-label="First Name" aria-describedby="basic-addon2"
                        onChange={(e) => SetForm({ ...form, firstName: e.target.value })}
                    />
                </div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Last Name" aria-label="Last Name" aria-describedby="basic-addon2"
                        onChange={(e) => SetForm({ ...form, lastName: e.target.value })}
                    />
                </div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon2"
                        onChange={(e) => SetForm({ ...form, email: e.target.value })}
                    />
                </div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon2"
                        onChange={(e) => SetForm({ ...form, password: e.target.value })}
                    />
                </div>
                <div>
                    <div className='row flex-row align-items-center justify-content-center text-center'><h2>Permissions</h2></div>
                    {/* Permissions */}
                    <div className='input-group mb-3 d-flex flex-row justify-content-between'>

                        <div className='d-flex justify-content-center flex-column'>
                            {permissions.map((it, i) => <div key={i}
                                onClick={() => {
                                    setPermissions(Array.from(permissions).filter(e => e !== it).sort((a, b) => {
                                        if (a < b) {
                                            return -1;
                                        } else if (a > b) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    }));
                                    const ap = Array.from(assignedPermissions);
                                    ap.push(it)
                                    ap.sort((a, b) => {
                                        if (a < b) {
                                            return -1;
                                        } else if (a > b) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    })
                                    setAssignedPermissions(ap)
                                }}>{it}</div>)}
                        </div>
                        <div className='d-flex justify-content-center flex-column'>
                            {assignedPermissions.map((it, i) => <div key={i}
                                onClick={() => {
                                    permissions.push(it)
                                    permissions.sort((a, b) => {
                                        if (a.name < b.name) {
                                            return -1;
                                        } else if (a.name > b.name) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    })
                                    setPermissions(permissions);
                                    setAssignedPermissions(Array.from(assignedPermissions).filter(e => e !== it).sort((a, b) => {
                                        if (a.name < b.name) {
                                            return -1;
                                        } else if (a.name > b.name) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    }));
                                }}>{it}</div>)}
                        </div>
                    </div>

                    <div className='row flex-row align-items-center justify-content-center text-center'><h2>Customers</h2></div>
                    {/* CUSTOMERS */}
                    <div className='input-group mb-3 d-flex flex-row justify-content-center'>

                        <div className='d-flex flex-column' style={{ maxHeight: "300px", overflow: 'scroll' }}>
                            {customers.map((it, i) => <div key={i}
                                onClick={() => {
                                    setCustomers(Array.from(customers).filter(e => e.id !== it.id));
                                    const ap = Array.from(assignedCustomers);
                                    ap.push(it)
                                    setAssignedCustomers(ap.sort((a, b) => {
                                        if (a.name < b.name) {
                                            return -1;
                                        } else if (a.name > b.name) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    }))
                                }}>{it.name}</div>)}
                        </div>
                        <div className='d-flex flex-column' style={{ maxHeight: "300px", overflow: 'scroll' }}>
                            {assignedCustomers.map((it, i) => <div key={i}
                                onClick={() => {
                                    customers.push(it)
                                    setCustomers(customers.sort((a, b) => {
                                        if (a.name < b.name) {
                                            return -1;
                                        } else if (a.name > b.name) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    }));
                                    setAssignedCustomers(Array.from(assignedCustomers).filter(e => e.id !== it.id)
                                        .sort((a, b) => {
                                            if (a.name < b.name) {
                                                return -1;
                                            } else if (a.name > b.name) {
                                                return 1;
                                            } else {
                                                return 0;
                                            }
                                        }));
                                }}>{it.name}</div>)}
                        </div>
                    </div>

                </div>
                <div className='btn-primary' onClick={() => {
                    handleSubmit();
                }}>Submit</div>

            </div>
        </div>
    </div>


}

CreateUserPopUp.propTypes = {
    closeCallback: func
}

export default CreateUserPopUp;