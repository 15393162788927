import React from 'react';
import { PropTypes } from 'prop-types';
import { DateTime } from 'luxon';
const CompetitionEntryRow = (props) => {


	const startFormatted  =DateTime.fromSeconds(props.data.competitionWindowStartUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
	const endFormatted  =DateTime.fromSeconds(props.data.competitionWindowEndUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
	const entryTime  =DateTime.fromSeconds(props.data.entryTimeUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
	return (
		<tr>
			<td>{props.data.id}</td>
			<td>{props.data.competitionName}</td>
			<td>{props.data.clientName}</td>
			<td>{props.data.stype=="external"?"PAID":"FREE"}</td>
			<td>{props.data.callId}</td>
			<td>{props.data.destinationNumber}</td>
			<td>{props.data.customerCli}</td>
			<td>{entryTime}</td>
			<td>{startFormatted}</td>
			<td>{endFormatted}</td>
		</tr>
	);
};

CompetitionEntryRow.propTypes = { 
	data: PropTypes.object.isRequired
};

export default CompetitionEntryRow;
