import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const getNumbersFromApi = createAsyncThunk(
    "adminNumbersSlice/getNumbers",
    async(search) => {
    
        const url = `/admin/numbers?search=${search}`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {

                    reject(err.response ? err.response.data : 'A network error occured.');
                })
                .finally(() => {
         
                });
        });
    }
);

const adminNumbersSlice = createSlice({
    name: "adminNumbers",
    initialState: {
        numbers: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getNumbersFromApi.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.numbers = action.payload.response;
            } else {
                state.numbers = [];
                state.error = action.payload.errorMessage;
            }
            state.loading = false;
            state.error = null;
        },
        [getNumbersFromApi.rejected]: (state, action) => {
            console.error("Could not get Numbers :", action);
            state.loading = false;
            state.error = `The Request Failed. ${
        action.error ? action.error.message : "AN ERROR OCCUED."
      }`;
        },
        [getNumbersFromApi.pending]: (state) => {
            state.error = null;
            state.loading = true;
        },
    },
});

export default adminNumbersSlice.reducer;
// export const { } = adminNumbersSlice.actions;
export const selectAdminNumbers = (state) => state.adminNumbers;