import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "../axios/axios";
import StatsPage from "../components/common/StatsPage";
import SecureAssistIFrame from "../components/secureassist/SecureAssistIFrame";
import { selectSelectedCustomer } from "../rdx/slices/customerSlice";

const SecureAssist = () => {
    const selectedCustomer = useSelector(selectSelectedCustomer);

    const { enqueueSnackbar } = useSnackbar();

    const [saUri, setSaUri] = useState({uri:null,r:0});

    useEffect(() => {
      
    loadPciScreen()
      return () => {
      
      }
    }, [])


    var bodyFormData = new URLSearchParams();
    bodyFormData.append("callslistbutton", "1");
    bodyFormData.append("r",window.performance.now())

    function loadPciScreen(){
        if (selectedCustomer) {
            axios
                .post(`/secure-assist/${selectedCustomer.id}/sign`, bodyFormData, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                })
                .then((ok) => {
                    //console.log(ok.data);
                    const uri = `https://pay.mi-dashboard.co.uk/secureassistproxy/secureassist.aspx?${ok.data}`;
                    //console.log(uri);
                    setSaUri({uri:uri,r:window.performance.now()});
                
                })
                .catch((err) => {
                    //console.error(err)
                    enqueueSnackbar(
                        err.response
                            ? err.response.data
                            : "Error obtaining a Secure Assist Instance.",
                        { variant: "error", autoHideDuration: 5000 }
                    );
                });
        }
    }


    var render = <div></div>;

   

    if (saUri.uri) {
        render = (
            <StatsPage
                title="PCI Payments"
                pretitle="Payments"
                style={{ height: "90%" }}
                render={
                    <Container
                        fluid="xl"
                        style={{
                            height: "100%",
                            minWidth: "90%",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            flexDirection: 'column',

                        }}
                    >
                        <Row className="d-flex flex-row-reverse" style={{ width: '100%' }}>

                            <div className="btn-success-rounded" style={{ width: "100px", 
                            background: '#78da93', 
                            color: 'white', 
                            border: 'solid 1px #e3ebf6', 
                            borderRadius: '5px',
                            marginRight:'20px',
                            marginBottom:'5px',
                            fontWeight:'bold',
                            textAlign: 'center' }}
                            onClick={()=>{
                                loadPciScreen(true);
                        
                            }}
                            >Reload</div>
                            {/* <div>{saUri.r}</div> */}
                        
                        </Row>
                        <Row style={{ width: '100%' }}>
                           <SecureAssistIFrame saUri={saUri}></SecureAssistIFrame>
                        </Row>
                    </Container>
                }
            />
        );
    } else {
        render = (
            <StatsPage
                title="PCI Payments"
                pretitle="Payments"
                style={{ height: "90%" }}
                render={<div></div>}
            />
        );
    }

    return render;
};

export default SecureAssist;
