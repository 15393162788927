import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getNumbersAvailable, selectNumbers } from "./temporaryOverridesSlice";
import { selectSelectedCustomer } from "../../rdx/slices/customerSlice";
import { PropTypes } from "prop-types";
import _ from "lodash";

const NumberSelector = (props) => {
    const numbers = useSelector(selectNumbers);
    const dispatch = useDispatch();
    const options = [];
    const [selection, setSelection] = useState([]);

    const [showDropDown, setShowDropDown] = useState(false);

    const customer = useSelector(selectSelectedCustomer);

    useEffect(() => {
        document.addEventListener("click", (event) => {
            if (!event.target.closest("#NumberDropDownSelector")) {
                setShowDropDown(false);
            } else {
                setShowDropDown(true);
            }
        });
    }, []);

    useEffect(() => {
        dispatch(getNumbersAvailable(customer.id));
    }, [customer]);

    useEffect(() => {
        props.onSelected(selection);
    }, [selection]);

    const formatOption = (number) => {
        return (
            <option key={number} value={number} id={number} name="NumberOption">
                {number.slice(0, 5) +
                    " " +
                    number.slice(5, 8) +
                    " " +
                    number.slice(8)}
            </option>
        );
    };

    const copy = [...(numbers ? numbers : [])];

    copy.forEach((number) => {
        options.push(formatOption(number));
    });

    return (
        <Form.Group
            controlId="selecty"
            type="number"
            placeholder="temporary override"
        >
            <div
                id="NumberDropDownSelector"
                className="card dropShadow pt-3 pb-3 text-center"
                style={{ minHeight: "46.5px",width:"571px" }}
            >
                <div className={`${selection.length == 0 ? "text-muted" : ""} w-100`} style={{overflow:'hidden',textOverflow:'clip'}}>
                    {`${selection.length > 0 ? "" : "Select Number(s)"}` +
                        `${_.cloneDeep(selection).splice(0, 3).join(", ")} ${
                            selection.length > 3
                                ? " +" + (selection.length - 3) + "..."
                                : ""
                        }`}
                </div>
                <select
                    id="TheSelector"
                    multiple
                    className="w-100"
                    style={{
                        display: showDropDown ? "block" : "none",
                        position: "absolute",
                        zIndex: "3",
                        height: "150px",
                        transform: "translate(0px,25px)",
                    }}
                    onChange={() => {
                        const options =
                            document.getElementsByName("NumberOption");
                        const selectedOptions = Array.from(options)
                            .filter((it) => it.selected)
                            .map((it) => {
                                return it.value;
                            });

                        setSelection(selectedOptions);
                    }}
                >
                    {options}
                </select>
            </div>
        </Form.Group>
    );
};

NumberSelector.propTypes = {
    onSelected: PropTypes.func.isRequired,
    showDropDown: PropTypes.bool.isRequired,
};

export default NumberSelector;
