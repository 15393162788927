export const formatSecondsToTime = (seconds) => {
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 3600 % 60);

    return `${padNumber(h)}:${padNumber(m)}:${padNumber(s)}`;
}

export const padNumber = (number) => {
    return number.toString().length != 1 ? number : "0" + number;
}