import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

const Date = (props) => {
  const [dateString, setDateString] = useState("...");

  useEffect(() => {
    const interval = setInterval(() => {
      setDateString(DateTime.now().setZone(props.zone).toFormat(props.format));
    },0, 60000);

    return () => {
      clearInterval(interval);
    };
  });

  return <h2 className="dateString">{dateString}</h2>;
};

Date.propTypes = {
  zone: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
};

export default Date;
