import React, { useEffect } from 'react';
import Date from '../home/date/Date';
import CustomerSelector from '../home/selector/CustomerSelector';
import { PropTypes } from 'prop-types';
import {useDispatch } from 'react-redux';
import {
	getCustomersForUserFromApi,
	// selectSelectedCustomer,
	// selectUsersCustomers,
	// setSelectedCustomer,
} from '../../rdx/slices/customerSlice';

const StatsPage = (props) => {
	const { title, pretitle, render } = props;
	const dispatch = useDispatch();
	// const usersCustomers = useSelector(selectUsersCustomers);
	// const selectedCustomer = useSelector(selectSelectedCustomer);

	/**
	 * This UseEffect runs once on the start of the Page.
	 * The customers of the user that they can access are fetched.
	 * Once recieved, the first in the list is 'selected' so the data is populated rather
	 * than showing a blank screen on load.
	 */
	useEffect(() => {
		dispatch(getCustomersForUserFromApi());
	}, []);

	// useEffect(() => {
	// 	if (selectedCustomer == null && usersCustomers) {
	// 		if (usersCustomers.length > 0) {
	// 			const topLevelCustomers = usersCustomers.filter(
	// 				(customer) => customer.parentId == null
	// 			);
	// 			if(topLevelCustomers.length == 0){
	// 				dispatch(setSelectedCustomer(usersCustomers[0]))
	// 			}else{
	// 				dispatch(setSelectedCustomer(topLevelCustomers[0].id));
	// 			}
	// 		}
	// 	}
	// }, [usersCustomers]);

	return (
		<div className='main-content statsPage'>
			<div className='header'>
				<div className='container-fluid'>
					<div className='header-body'>
						<div className='row justify-content-between'>
							<div
								className='col-md-4 col-sm-12 align-self-center'
								//align='start'
							>
								<h6 className='header-pretitle'>{pretitle}</h6>
								<h1 className='header-title'>{title}</h1>
							</div>
							<div className='col-md-4 col-sm-12 align-self-end'>
								<div className='row'>
									<CustomerSelector></CustomerSelector>
								</div>
								<div className='row justify-content-around'>
									<div className='col-12' //align='end'
									>
										<Date
											format={'cccc dd LLLL'}
											zone='Europe/London'
										></Date>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{render}
		</div>
	);
};

StatsPage.propTypes = {
	title: PropTypes.string.isRequired,
	pretitle: PropTypes.string.isRequired,
    render: PropTypes.object,
};

export default StatsPage;
