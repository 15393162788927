import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import TableHeader from "../common/TableHeader";
import { selectSelectedCustomer } from "../../rdx/slices/customerSlice";
import { useSelector } from "react-redux";
import NumberRow from "./NumberRow";
import { useDispatch } from "react-redux";
import {
    getTemporaryOverrideTable,
    selectTemporaryOverrides,
    selectTemporaryOverrideState,
    setEditingTo,
} from "./temporaryOverridesSlice";
import { removeRouting } from "./TemporaryOverrideAPI";

const TemporaryOverrideNumbersTable = () => {
    const toState = useSelector(selectTemporaryOverrideState);
    const tempOverrides = useSelector(selectTemporaryOverrides);
    const selectedCustomer = useSelector(selectSelectedCustomer);
    const dispatch = useDispatch();
    const [paging, setPaging] = useState({
        page: 0,
        limit: 10,
        totalElements: 0,
    });

    useEffect(() => {}, [paging]);

    useEffect(() => {
        setPaging({ ...paging, totalElements: tempOverrides.length });
    }, [tempOverrides]);

    useEffect(() => {
        if (selectedCustomer) {
            dispatch(getTemporaryOverrideTable(selectedCustomer.id));
        }
        return () => {};
    }, [selectedCustomer]);

    function handleRemoveRouting(to) {
        removeRouting(selectedCustomer.id, to.id)
            .then(() => {
                dispatch(getTemporaryOverrideTable(selectedCustomer.id));
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function hasMorePages() {
        const currentPage = paging.page + 1;
        const limit = paging.limit;
        return limit * currentPage < paging.totalElements;
    }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => setPaging({ ...paging, page: pageNumber })}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        for (
            var i = 0;
            i < toState.temporaryOverrides.length / paging.limit;
            i++
        ) {
            render.push(formatPageElement(i, paging.page === i));
        }

        return render;
    };

    return (
        <div>
            <div className="container-fluid tableContainer">
                <div className="card" style={{ overflow: "auto" }}>
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="card-header-title">Numbers</h3>
                            </div>
                        </div>
                    </div>

                    <Table
                        className="table table-sm card-table"
                        responsive="sm"
                    >
                        <thead>
                            <tr>
                                <TableHeader id={"number"} name="NUMBER" />
                                <TableHeader
                                    id={"description"}
                                    name="DESCRIPTION"
                                    onSort={null}
                                />
                                <TableHeader id={"dtype"} name={<span>DEFAULT<br></br>DESTINATION TYPE</span>} />
                                <TableHeader
                                    id={"existingRouting"}
                                    name={<span>DEFAULT ROUTING<br></br>DESTINATION</span>}
                                    onSort={null}
                                />

                                <TableHeader
                                    id={"overrideDest"}
                                    name={<span>OVERRIDE ROUTING<br></br>DESTINATION</span>}
                                    onSort={null}
                                />
                                <TableHeader
                                    id={"overrideRouting"}
                                    name={<span>OVERRIDE<br></br>ROUTING</span>}
                                    onSort={null}
                                    centered={true}
                                />
                                <TableHeader
                                    id={"editRouting"}
                                    name={<span>EDIT<br></br>ROUTING</span>}
                                    onSort={null}
                                    centered={true}
                                />
                                <TableHeader
                                    id={"deleteRouting"}
                                    name={<span>DELETE<br></br>ROUTING</span>}
                                    onSort={null}
                                    centered={true}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {tempOverrides
                                ? tempOverrides
                                      .slice(
                                          paging.page * paging.limit,
                                          paging.page * paging.limit +
                                              paging.limit
                                      )
                                      .map((it) => (
                                          <NumberRow
                                              key={`${it.id}`}
                                              data={it}
                                              onEdit={() => {
                                                  dispatch(setEditingTo(it));
                                              }}
                                              onRemoveRouting={() => {
                                                  handleRemoveRouting(it);
                                              }}
                                          />
                                      ))
                                : ""}
                        </tbody>
                    </Table>

                    <div className="card-footer d-flex justify-content-between">
                        {paging.page > 0 ? (
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-0 pe-4 border-end"
                                        onClick={() =>
                                            setPaging({
                                                ...paging,
                                                page: paging.page - 1,
                                            })
                                        }
                                    >
                                        <i className="fe fe-arrow-left me-1"></i>{" "}
                                        Prev
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                            {pageElements()}
                        </ul>
                        {hasMorePages() ? (
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-4 pe-0 border-start"
                                        onClick={() =>
                                            setPaging({
                                                ...paging,
                                                page: paging.page + 1,
                                            })
                                        }
                                    >
                                        Next{" "}
                                        <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemporaryOverrideNumbersTable;
