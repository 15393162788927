import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getSoundTasks,
    selectsoundTasks,
} from "../../../rdx/slices/soundTasksSlice";
import _ from "underscore";
import { Table } from "react-bootstrap";
import TableHeader from "../../common/TableHeader";
import { selectSelectedCustomer } from "../../../rdx/slices/customerSlice";
import PropTypes from "prop-types";
import SoundsTasksTableRow from "./SoundsTasksTableRow";
import axios from "../../../axios/axios";
import TableFilter from "../../common/TableFilter";
import { selectSounds } from "../../../rdx/slices/soundsSlice";

/**
 *
 * A SoundTask table showing the SoundTasks for a customer.
 *
 * @returns A SoundTask Pageable and Sortable Table.
 */
const SoundTasksTable = (props) => {
    const dispatch = useDispatch();

    //The currently selected customer.
    const selectedCustomer = useSelector(selectSelectedCustomer);

    const defaultQuery = {
        customerId: selectedCustomer.id,
        page: 0,
        size: 10,
        sortBy: "id",
        sortDir: "DESC",
        filter: { soundId: null, soundTaskState: null },
    };

    // The Query that when updated is re-run.
    const [query, setQuery] = React.useState(defaultQuery);

    const data = useSelector(selectsoundTasks, _.isEqual);
    const sounds = useSelector(selectSounds);

    const handleFilterSelection = (filterSelection) => {
        setQuery({
            ...query,
            filter: {
                ...query.filter,
                [filterSelection.filter]: filterSelection.value,
            },
        });
    };

    const handleClearFilter = (filter) => {
        setQuery({ ...query, filter: { ...query.filter, [filter]: null } });
    };

    const applySort = (field) => {
        const currentSortBy = query.sortBy;
        const currentDir = query.sortDir;

        let newDir = "";
        if (field == currentSortBy) {
            newDir = currentDir == "ASC" ? "DESC" : "ASC";
        } else {
            newDir = "ASC";
        }

        setQuery({ ...query, sortBy: field, sortDir: newDir });
    };

    //Use Effect, reset query when new Selected Customer is changed.
    useEffect(() => {
        setQuery(defaultQuery);
    }, [selectedCustomer]);

    // If the query changes, re-do the request.
    useEffect(() => {
        dispatch(getSoundTasks(query));

        const interval = setInterval(() => {
            dispatch(getSoundTasks(query));
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [query]);

    function cancelSoundTask(soundTask) {
        axios
            .post(
                `/sounds/customer/${selectedCustomer.id}/sound-task/${soundTask.id}/cancel`,
                { withCredentials: true, responseType: "blob" }
            )
            .then(() => {
                dispatch(getSoundTasks(query));
            })
            .catch((err) => {
                alert(err.response ? err.response.data : err.status);
            });
    }

    // Clone the data (Deep Clone)
    const cloned = _.clone(data.content ? data.content : []);

    function downloadSound(soundTask) {
        axios
            .get(
                `/sounds/customer/${selectedCustomer.id}/sound-task/${soundTask.id}/sound`,
                { withCredentials: true, responseType: "blob" }
            )
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `soundTask_${soundTask.id}.wav`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                alert(JSON.stringify(err));
            });
    }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => setQuery({ ...query, page: pageNumber })}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        if (data.pageable) {
            for (
                var i = Math.max(data.pageable.pageNumber - 5, 0);
                i < Math.min(data.totalPages, data.pageable.pageNumber + 5);
                i++
            ) {
                const active = data.pageable.pageNumber === i;
                render.push(formatPageElement(i, active));
            }
        }
        return render;
    };

    const soundNameFilter = (
        <TableFilter
            filter="soundId"
            options={sounds.map((it) => {
                return { label: it.name, value: it.id };
            })}
            onSelection={handleFilterSelection}
            onClearSelection={handleClearFilter}
        />
    );

    const stateOptions = [
        { value: "LIVE", label: "Live" },
        { value: "QUEUED", label: "Processing" },
        { value: "AWAITING_LIVE", label: "Scheduled" },
        { value: "CANCELLED", label: "Cancelled" },
        { value: "RETIRED", label: "Retired" },
    ];

    const soundTaskStateFilter = (
        <TableFilter
            filter="soundTaskState"
            options={stateOptions}
            onSelection={handleFilterSelection}
            onClearSelection={handleClearFilter}
        />
    );

    return (
        <div
            className="container-fluid tableContainer col-12"
            style={{ minHeight: "600px" }}
        >
            <div className="card" style={{ overflow: "auto" }}>
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="d-flex row align-items-center justify-content-between flex-grow-1">
                                <h3 className="d-flex col card-header-title flex-grow-1">
                                    Sound Upload Tasks
                                </h3>
                                <div className="d-flex col align-items-center align-content-center justify-content-end flex-grow-1 p-0 m-0">
                                    <div
                                        id="CSVUploadBtn"
                                        className="fe fe-file m-3 text-info"
                                        onClick={() => {
                                            props.onCSVUploadClicked();
                                        }}
                                    />
                                    <div
                                        id="NewSoundTaskBtn"
                                        className="fe fe-plus-circle m-3 text-info"
                                        onClick={() => {
                                            props.onUploadClicked();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    className="table table-sm table-nowrap card-table"
                    responsive="sm"
                >
                    <thead>
                        <tr>
                            <TableHeader
                                id={"id"}
                                name="TASK ID"
                                onSort={(id) => applySort(id)}
                            />
                            <TableHeader
                                id={"enswitchSoundId"}
                                name="SOUND NAME"
                                onSort={null}
                            />
                            <TableHeader
                                id={"state"}
                                name="STATE"
                                onSort={(id) => applySort(id)}
                            />

                            <TableHeader
                                id={"makeLiveTimeUTC"}
                                name="GO LIVE TIME"
                                onSort={(id) => applySort(id)}
                            />
                            <TableHeader
                                id={"content"}
                                name="CONTENT"
                                onSort={(id) => applySort(id)}
                            />
                            <TableHeader
                                id={"createdAt"}
                                name="CREATED"
                                onSort={(id) => applySort(id)}
                            />

                            <th className="text-muted" />
                            <th className="text-muted" />
                        </tr>
                        <tr>
                            <th className="text-muted"></th>
                            {soundNameFilter}
                            {soundTaskStateFilter}
                            <th className="text-muted" />
                            <th className="text-muted" />
                            <th className="text-muted" />
                            <th className="text-muted" />
                            <th className="text-muted" />
                        </tr>
                    </thead>
                    <tbody>
                        {cloned.map((d, i) => (
                            <SoundsTasksTableRow
                                key={`${i}-${d.id}`}
                                data={d}
                                onDownloadClicked={(st) => downloadSound(st)}
                                onCancelSoundTaskClicked={(st) =>
                                    cancelSoundTask(st)
                                }
                            />
                        ))}
                    </tbody>
                </Table>
                <div className="card-footer d-flex justify-content-between">
                    {data.number > 0 ? (
                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                            <li className="page-item">
                                <button
                                    className="page-link ps-0 pe-4 border-end"
                                    onClick={() =>
                                        setQuery({
                                            ...query,
                                            page: query.page - 1,
                                        })
                                    }
                                >
                                    <i className="fe fe-arrow-left me-1"></i>{" "}
                                    Prev
                                </button>
                            </li>
                        </ul>
                    ) : (
                        <ul></ul>
                    )}
                    <ul className="list-pagination pagination pagination-tabs card-pagination">
                        {pageElements()}
                    </ul>
                    {data.number < data.totalPages - 1 ? (
                        <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                            <li className="page-item">
                                <button
                                    className="page-link ps-4 pe-0 border-start"
                                    onClick={() =>
                                        setQuery({
                                            ...query,
                                            page: query.page + 1,
                                        })
                                    }
                                >
                                    Next{" "}
                                    <i className="fe fe-arrow-right ms-1"></i>
                                </button>
                            </li>
                        </ul>
                    ) : (
                        <ul></ul>
                    )}
                </div>
            </div>
        </div>
    );
};

SoundTasksTable.propTypes = {
    onCSVUploadClicked: PropTypes.func.isRequired,
    onUploadClicked: PropTypes.func.isRequired,
};

export default SoundTasksTable;
