import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import LiveCallsTableRow from './LiveCallsTableRow';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectInboundCalls } from '../../../rdx/slices/activeCallsSlice';
import TableHeader from '../../common/TableHeader';
import _ from 'underscore';

const LiveCallsTable = () => {
	/**
	 * Select the data for live inbound calls.
	 */
	const data = useSelector(selectInboundCalls, _.isEqual);

	const [sortBy, setSortBy] = useState({
		fieldName: 'start',
		dir: 'ASC',
	});

	var dataCopy = JSON.parse(JSON.stringify(data));

	const onSortHeader = (id) => {
		if (sortBy.fieldName === id) {
			if (sortBy.dir == 'ASC') {
				setSortBy({ fieldName: id, dir: 'DESC' });
			} else {
				setSortBy({ fieldName: id, dir: 'ASC' });
			}
		} else {
			setSortBy({ fieldName: id, dir: 'ASC' });
		}
	};

	if (sortBy !== null) {
		dataCopy = dataCopy.sort((o1, o2) => {
			if (o1[sortBy.fieldName] > o2[sortBy.fieldName])
				return sortBy.dir == 'ASC' ? 1 : -1;
			if (o1[sortBy.fieldName] == o2[sortBy.fieldName]) return 0;
			if (o1[sortBy.fieldName] < o2[sortBy.fieldName])
				return sortBy.dir == 'ASC' ? -1 : 1;
		});
	}

	// /**
	//  * DeepCopy data as it is immutable and then sort it by start time so the most
	//  * recent call start time is at the top of the table.
	//  */
	// const dataDeepCopy = JSON.parse(JSON.stringify(data))

	// if(dataDeepCopy){
	// 	dataDeepCopy.sort((a,b)=>{
	// 		return (a.start - b.start)
	// 	})
	// }

	return (
		<div className='container-fluid tableContainer'>
			<div className='card' style={{ overflow: 'auto' }}>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col'>
							<h3 className='card-header-title'>
								Live Inbound Calls
							</h3>
						</div>
					</div>
				</div>
				<Table
					className='table table-sm table-nowrap card-table'
					responsive='sm'
				>
					<thead>
						<tr>
							<th
								className='text-muted'
								style={{ width: '80px' }}
							></th>
							<TableHeader
								id={'callStatus'}
								name='CALL STATUS'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'start'}
								name='START'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'service'}
								name='SERVICE'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'callStage'}
								name='CALL STAGE'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'callType'}
								name='CALL TYPE'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'customerNo'}
								name='From'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'dialledNo'}
								name='To'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'start'}
								name='DURATION'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'agent'}
								name='AGENT'
								onSort={onSortHeader}
							/>
						</tr>
					</thead>
					<tbody>
						{dataCopy.map((d, i) => (
							<LiveCallsTableRow
								key={`${d.customerNo}-${d.dialledNo}-${d.start}-${i}`}
								data={d}
							/>
						))}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

LiveCallsTable.propTypes = {
	customerId: PropTypes.number.isRequired,
};

export default LiveCallsTable;
