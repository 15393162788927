import React from "react";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectStateChangeMap, toggleState } from "./temporaryOverridesSlice";
const NumberRow = (props) => {
    const dispatch = useDispatch();
    const stateMap = useSelector(selectStateChangeMap);

    const number = props.data.number;
    const desc = props.data.description;
    const defaultDestination = props.data.defaultDestination;
    const defaultDtype = props.data.dtype;
    const overrideDestination = props.data.overrideDestination;
    const currentDestination = props.data.currentDestination;

    const btnToggled = stateMap.get(props.data.id);

    return (
        <tr style={{ backgroundColor: "white" }}>
            <td>{number}</td>
            <td
                style={{
                    maxWidth: "5px",
                    wordWrap: "normal",
                    whiteSpace: "normal",
                }}
            >
                {desc}
            </td>
            <td>{defaultDtype}</td>
            <td
                style={{
                    color:
                        defaultDestination == currentDestination ||
                        currentDestination == null
                            ? "black"
                            : "grey",
                }}
            >
                <div className="d-flex align-items-center">
                    <div
                        className="green-circle m-1"
                        style={{
                            visibility:
                                defaultDestination == currentDestination
                                    ? "visible"
                                    : "hidden",
                        }}
                    ></div>
                    <div>{defaultDestination}</div>{" "}
                </div>
            </td>
            <td
                style={{
                    color:
                        overrideDestination == currentDestination
                            ? "black"
                            : "grey",
                }}
            >
                <div className="d-flex align-items-center">
                    <div
                        className="green-circle m-1"
                        style={{
                            visibility:
                                overrideDestination == currentDestination
                                    ? "visible"
                                    : "hidden",
                        }}
                    ></div>
                    <div>{overrideDestination}</div>{" "}
                </div>
            </td>
            <td className="p-0">
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <label className="switch text-muted h-100">
                        <input
                            type="checkbox"
                            checked={btnToggled}
                            onChange={() => {
                                dispatch(toggleState(props.data.id));
                            }}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </td>
            <td
                onClick={() => {
                    props.onEdit(props.data);
                }}
                style={{ padding: "0px" }}
            >
                <div className="h-100 w-100 d-flex align-items-center justify-content-center text-center">
                    <i
                        className="fe fe-edit text-muted "
                        style={{ fontSize: "25px" }}
                    ></i>
                </div>
            </td>
            <td
                onClick={() => {
                    props.onRemoveRouting(props.data);
                }}
                style={{ padding: "0px" }}
            >
                <div className="h-100 w-100 d-flex align-items-center justify-content-center text-center">
                    <i
                        className="fe fe-x-circle"
                        style={{ fontSize: "25px", color: "red" }}
                    ></i>
                </div>
            </td>
        </tr>
    );
};

NumberRow.propTypes = {
    data: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemoveRouting: PropTypes.func.isRequired,
};

export default NumberRow;
