import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { DateTime } from "luxon";
import PillIcon from "../common/PillIcon";
import axios from "../../axios/axios";
import { useSelector } from "react-redux";
import { selectSelectedCompBrand } from "../../rdx/slices/compSlice";

const CompCSVDownloadPopUp = (props) => {
    const [name, setName] = React.useState(null);

    const selectedBrand = useSelector(selectSelectedCompBrand);

    useEffect(() => {
        const name =
            props.data.competitionName + " " + props.data.startTimeEuLon;
        setName(name.replace(new RegExp("[^A-z0-9,£,&]", "gm"), "_"));
    }, []);

    const diff = DateTime.fromSeconds(props.data.endTimeUnix).diff(
        DateTime.fromSeconds(props.data.startTimeUnix),
        ["months", "days", "hours"]
    );

    function download(allClients) {
        const url = allClients
            ? `/competitions/brand/${selectedBrand.id}/window/${props.data.competitionWindowId}/entries`
            : `/competitions/brand/${selectedBrand.id}/client/${props.data.clientId}/window/${props.data.competitionWindowId}/entries`;
        axios
            .get(url, { responseType: "blob" })
            .then((ok) => {
                const url = window.URL.createObjectURL(new Blob([ok.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", name + ".csv");
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                alert(JSON.stringify(err));
            });
    }

    return (
        <div className="popup" onClick={() => props.onClose()}>
            <div
                className="modal fade show"
                id="exampleModal"
                style={{ tabIndex: "-1" }}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Modal title
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">...</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="container-sm card p-5"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div
                    className="position-absolute p-4 top-0 end-0 d-flex"
                    onClick={() => props.onClose()}
                >
                    <div className="fe fe-x-circle"></div>
                </div>
                <div className="row m-1 text-center p-2">
                    <h2>Download CSV of Entries</h2>
                    <h3>{props.data.competitionName}</h3>
                </div>
                <div className="row m-1 text-center p-2">
                    <h5 className="p-2">
                        Window : {props.data.competitionWindowId}
                    </h5>
                    <h5>
                        Started At:{" "}
                        {DateTime.fromSeconds(
                            props.data.startTimeUnix
                        ).toFormat("DD HH:mm:ss ZZZZ", {
                            zone: "Europe/London",
                            locale: "en-GB",
                        })}
                    </h5>
                    <h5>
                        Ended At :{" "}
                        {DateTime.fromSeconds(props.data.endTimeUnix).toFormat(
                            "DD HH:mm:ss ZZZZ",
                            { zone: "Europe/London", locale: "en-GB" }
                        )}
                    </h5>
                    <h5 className="m-2">
                        {diff.months} months, {diff.days} days, {diff.hours}{" "}
                        hours.
                    </h5>

                    <div className="col">
                        <PillIcon
                            text={props.data.state}
                            status={
                                props.data.state == "LIVE"
                                    ? "success"
                                    : props.data.state == "COMPLETE"
                                    ? "info"
                                    : "warning"
                            }
                        ></PillIcon>
                    </div>
                </div>
                <div className="row justify-content-around p-3">
                    <input
                        className="col-6"
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    ></input>
                </div>
                <div className="p-3 row justify-content-around m-1">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => download(false)}
                        className="m-2 text-center col-lg-3 col-sm-5 p-1 btn-primary"
                    >
                        For {props.data.clientName}
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => download(true)}
                        className="m-2 text-center col-lg-3 col-sm-5 p-1 btn-info"
                    >
                        For All Clients
                    </div>
                </div>
            </div>
        </div>
    );
};

CompCSVDownloadPopUp.propTypes = {
    data: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CompCSVDownloadPopUp;
