import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { sortByNameField } from "../../utils/sortUtils";

export const getCustomersForUserFromApi = createAsyncThunk(
    "customerSlice/getCustomersForUserFromApi",
    async () => {
        const url = `/customers`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
);

const customersSlice = createSlice({
    name: "customers",
    initialState: {
        usersCustomers: [],
        selectedCustomer: null,
        loading: false,
    },
    reducers: {
        setSelectedCustomer: (state, action) => {
            state.selectedCustomer = state.usersCustomers.filter(
                (it) => it.id == action.payload
            )[0];
        },
    },
    extraReducers: {
        [getCustomersForUserFromApi.fulfilled]: (state, action) => {
            state.usersCustomers = sortByNameField(action.payload);

            if (state.selectedCustomer == null) {
                const topLevelCustomers = action.payload.filter(
                    (customer) => customer.parentId == null
                );

                if (topLevelCustomers.length == 0) {
                    const childCustomers = sortByNameField(action.payload);
                    state.selectedCustomer = childCustomers[0];
                } else {
                    state.selectedCustomer =
                        sortByNameField(topLevelCustomers)[0];
                }

                //state.selectedCustomer = sortByNameField(action.payload)[0];
            }
            state.loading = false;
        },
        [getCustomersForUserFromApi.rejected]: (state, action) => {
            console.error("Could not get Customers :", action.payload);
            state.loading = false;
        },
        [getCustomersForUserFromApi.pending]: (state) => {
            state.loading = true;
        },
    },
});

export default customersSlice.reducer;
export const { setSelectedCustomer } = customersSlice.actions;
export const selectUsersCustomers = (state) => state.customers.usersCustomers;
export const selectSelectedCustomer = (state) =>
    state.customers.selectedCustomer;
