import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const getSoundTasks = createAsyncThunk(
  "soundTasks/getSoundTasksOfCustomer",
  async (payload) => {

    let url = `/sounds/customer/${payload.customerId}/sound-task/pageable?page=${payload.page}&size=${payload.size}&sort=${payload.sortBy},${payload.sortDir}`;

    if(payload.filter){
    Object.entries(payload.filter).forEach(e=>{
      const [key, value] = e;
      if(value){
        url+=`&${key}=${value}`
      }
    })
  }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
);

const soundTasksSlice = createSlice({
  name: "soundTasks",
  initialState: {
    soundTasksState: { error: null, soundTasks: [], loading: false },
  },
  reducers: {
    setSoundTasks: (state) => {
      state.soundTasksState = { error: null, soundTasks: [], loading: false }
    }
  },
  extraReducers: {
    [getSoundTasks.fulfilled]: (state, action) => {
      state.soundTasksState = { error: null, soundTasks: action.payload, loading: false }
    },
    [getSoundTasks.rejected]: (state, action) => {
      console.error("Could not get customer SoundTasks. :", action.payload)
      state.soundTasksState = { soundTasks: [], error: "We could not get the Sound Tasks for customer.", loading: false }
    },
    [getSoundTasks.pending]: (state) => {
      state.soundTasksState = { ...state.soundTasksState, loading: true }
    },
  },
});

export default soundTasksSlice.reducer;
export const { clearActiveCallData } = soundTasksSlice.actions;
export const selectsoundTasks = (state) => state.soundTasks.soundTasksState.soundTasks;
export const selectsoundTasksState = (state) => state.soundTasks.soundTasksState;

