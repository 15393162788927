import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../rdx/slices/customerSlice";
import { overrideNumbers } from "../TemporaryOverrideAPI";

import {
    CREATE_NEW_POPUP,
    getTemporaryOverrideTable,
    selectInitialStateMap,
    selectIsTOStateDifference,
    selectStateChangeMap,
    showPopUp,
} from "../temporaryOverridesSlice";
import AllToggleBtn from "./AllToggleBtn";

const Toolbar = () => {
    const dispatch = useDispatch();

    const updatesMap = useSelector(selectStateChangeMap);
    const initialMap = useSelector(selectInitialStateMap);
    const customer = useSelector(selectSelectedCustomer);
    const changesPending = useSelector(selectIsTOStateDifference);

    const { enqueueSnackbar } = useSnackbar();

    function handleSave() {
        const updates = [];

        initialMap.forEach((value, key) => {
            const updated = updatesMap.get(key);
            if (value !== updated) {
                updates.push({ id: key, initial: value, new: updated });
            }
        });
        console.table(updates);

        overrideNumbers(customer.id, updates)
            .then((response) => {
                if (response.allSuccessful) {
                    enqueueSnackbar("All Overrides Applied.", {
                        variant: "success",
                        autoHideDuration: 3000,
                    });
                } else {
                    enqueueSnackbar("Not All Overrides were Successful!", {
                        variant: "warning",
                        autoHideDuration: 3000,
                    });
                }

                dispatch(getTemporaryOverrideTable(customer.id));
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(
                    err.response ? err.response.data : "We could not do that.",
                    {
                        variant: "error",
                        autoHideDuration: 3000,
                    }
                );
            });
    }

    return (
        <div className="row d-flex flex-wrap justify-content-end mb-2">
            <div className="col-sm-8 col-lg-3 d-flex flex-row d-flex justify-content-around align-items-center ">
                <div className="col-1 pb-2 w-25">
                    <div className="d-flex flex-column align-items-center">
                        <h6 className="text-muted text-center">
                            OVERRIDE ALL NUMBERS
                        </h6>
                        <AllToggleBtn />
                    </div>
                </div>
                <div className="col-1 w-25">
                    <div className="h-100 d-flex flex-column align-items-center justify-content-between">
                        <div>
                            <h6 className="text-muted text-center bolder-font p-0 m-0">
                                NEW OVERRIDE
                            </h6>
                        </div>
                        <div
                            className="fe fe-plus-circle"
                            style={{
                                fontSize: "28px",
                                color: "#00D97E",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                dispatch(showPopUp(CREATE_NEW_POPUP));
                            }}
                        />
                    </div>
                </div>
                <div
                    className="col-4 h-100 w-50 d-flex flex-row align-items-end pb-2"
                    onClick={() => handleSave()}
                >
                    <div
                        className="btn-success rounded w-100 text-center shadow d-flex align-items-center justify-content-center"
                        style={{
                            height: "35px",
                            background: changesPending ? "#00D97E" : "#95AAC9",
                            cursor: "pointer",
                        }}
                    >
                        <h5 className="p-0 m-0">Save Changes</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toolbar;
