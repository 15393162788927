import React from 'react';
import PropTypes from 'prop-types';
import getColorViaHash from '../../../utils/hashColourUtil';

const AgentLabel = (props) => {
	const format = (agent) => {
		const upper = agent.toUpperCase();
		const array = upper.split(' ');
		if (array.length > 1) {
			return array[0].charAt(0) + array[array.length - 1].charAt(0);
		}
		return array[0].charAt(0);
	};
	let render = null;
	if (props.agent) {
		render = (
			<div className='circle' style={{backgroundColor:getColorViaHash(props.agent)}}>
				<div className='circle-text'>{format(props.agent)}</div>
			</div>
		);
	}
	return render;
};

AgentLabel.propTypes = {
	agent: PropTypes.string.isRequired,
};

export default AgentLabel;
