import React from 'react';
import { func, PropTypes } from 'prop-types';
import PillIcon from '../common/PillIcon';
//import { DateTime } from 'luxon';
const UserRow = (props) => {

	// const startFormatted  =DateTime.fromSeconds(props.data.competitionWindowStartUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
	// const endFormatted  =DateTime.fromSeconds(props.data.competitionWindowEndUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
	// const entryTime  =DateTime.fromSeconds(props.data.entryTimeUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
	return (
		<tr>
			<td>{props.data.id}</td>
			<td>{props.data.email}</td>
			<td><PillIcon status={props.data.enabled ? "success" : "error"} text={props.data.enabled ? "ENABLED" : "DEACTIVATED"} /></td>
			<td>{props.data.firstName}</td>
			<td>{props.data.lastName}</td>
			<td>{props.data.role}</td>
			<td>{props.data.createdAt}</td>
			<td className='fe changePassword' onClick={() => {
				console.log("PRESS")
				props.onChangePasswordCallback(props.data)
			}}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="fe feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg></td>
		</tr>
	);
};

UserRow.propTypes = {
	data: PropTypes.object.isRequired,
	onChangePasswordCallback: func.isRequired
};

export default UserRow;
