import React from "react";
import UsersTable from "../components/admin/UsersTable";
import Date from "../components/home/date/Date";
import _ from "underscore";
import NumbersTable from "../components/admin/NumbersTable";

// import { useDispatch } from "react-redux";

const Admin = () => {
  //   const dispatch = useDispatch();

  //   dispatch(getNumbersFromApi("0330880"));
  // dispatch(getNumbersFromApi("0330"));


  const [selectedOption, setSelectedOption] = React.useState({
    name: "USERS",
    component: <UsersTable></UsersTable>,
  });

  const buttonTypes = [
    { name: "USERS", component: <UsersTable></UsersTable> },
    {
      name: "NUMBERS",
      component: <NumbersTable></NumbersTable>,
    },
  ];

  const buttons = buttonTypes.map((it) => {
    return (
      <div
        key={it.name}
        className={`${
          _.isEqual(selectedOption.name, it.name)
            ? "btn-primary"
            : "btn-secondary"
        } m-2 px-5 py-2 col-auto`}
        //tag={it}
        onClick={() => setSelectedOption(it)}
      >
        {it.name}
      </div>
    );
  });

  return (
    <div className="main-content statsPage">
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row justify-content-between">
              <div
                className="col-md-4 col-sm-12 align-self-center"
                //align="start"
              >
                <h6 className="header-pretitle">ADMIN</h6>
                <h1 className="header-title">ADMINISTRATOR</h1>
              </div>
              <div className="col-md-4 col-sm-12 align-self-end">
                <div className="row justify-content-around">
                  <div className="col-12" 
                  //align="end"
                  >
                    <Date format={"cccc dd LLLL"} zone="Europe/London"></Date>
                  </div>
                </div>
              </div>
            </div>
            <div className="row w-100 mt-3">{buttons}</div>
          </div>
        </div>
      </div>
      {selectedOption != null ? (
        selectedOption.component
      ) : (
        <UsersTable/>
      )}
      {/* {render} */}
    </div>
  );
};

export default Admin;
