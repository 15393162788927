import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../rdx/slices/customerSlice";
import {
    CREATE_NEW_POPUP,
    getTemporaryOverrideTable,
    selectDisplayPopUp,
    showPopUp,
} from "./temporaryOverridesSlice";
import NumberSelector from "./NumberSelector";
import { createOverride } from "./TemporaryOverrideAPI";
import { useSnackbar } from "notistack";

const CreateOverridePopUp = () => {
    const [form, setForm] = useState({
        numbers: [],
        overrideDestination: "",
        countryCode: "GB",
    });

    const customer = useSelector(selectSelectedCustomer);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    function doRESTRequest() {
        createOverride(
            customer.id,
            form.numbers,
            form.overrideDestination.replaceAll("[^0-9]", ""),
            form.countryCode
        )
            .then(() => {
                dispatch(getTemporaryOverrideTable(customer.id));
                dispatch(showPopUp(false));
                setForm({
                    numbers: [],
                    overrideDestination: "",
                    countryCode: "GB",
                });
                enqueueSnackbar("New Overrides Created.", {
                    variant: "success",
                    autoHideDuration: 3000,
                });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(
                    err.response
                        ? err.response.data
                            ? err.response.data
                            : "There was a problem"
                        : "There was an issue.",
                    {
                        variant: "error",
                        autoHideDuration: 3000,
                    }
                );
            });
    }

    const render = (
        <div className="popup">
            <div className="card p-5">
                <div
                    className="fe fe-x-circle"
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "15px",
                        top: "15px",
                        color: "#95AAC9",
                        fontSize: "25px",
                    }}
                    onClick={() => {
                        dispatch(showPopUp(null));
                    }}
                ></div>
                <div className="row">
                    <div className="col-12 text-center">
                        <span className="h1 bold">
                            Add new temporary override
                        </span>
                    </div>
                </div>

                <div className="row pt-5 align-items-center justify-content-center">
                    <Form className="col-md-6 col-12 p-3 pb-3">
                        <Form.Label>
                            <span className="h4 bold">Number</span>
                        </Form.Label>
                        <NumberSelector
                            onSelected={(numbers) => {
                                console.log(numbers);
                                form.numbers = numbers;
                            }}
                        />

                        <Form.Group>
                            <Form.Label>
                                <span className="h4 bold">
                                    Destination Number
                                </span>
                            </Form.Label>
                            <div
                                className="row d-flex align-items-center justify-content-center"
                                style={{ width: "579px" }}
                            >
                                <div className="p-0" style={{ width: "100px" }}>
                                    <Form.Control
                                        className="card dropShadow pt-3 pb-3 text-center"
                                        as="select"
                                        onChange={(event) =>
                                            setForm({
                                                ...form,
                                                countryCode: event.target.value,
                                            })
                                        }
                                        value={form.countryCode}
                                    >
                                        <option key={1} value={"GB"} id={1}>
                                            🇬🇧 (+44)
                                        </option>
                                        <option key={2} value={"IE"} id={2}>
                                            🇮🇪 (+35)
                                        </option>
                                    </Form.Control>
                                </div>
                                <div
                                    className="pl-1"
                                    style={{ width: "459px" }}
                                >
                                    <Form.Control
                                        className="card dropShadow pt-3 pb-3 text-center"
                                        style={{ width: "459px" }}
                                        type="text"
                                        placeholder="Destination"
                                        onChange={(event) =>
                                            setForm({
                                                ...form,
                                                overrideDestination:
                                                    event.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                    <div
                        className="d-flex btn-success rounded text-center align-items-center justify-content-center"
                        style={{
                            minWidth: "30vw",
                            maxWidth: "600px",
                            minHeight: "50px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            doRESTRequest();
                        }}
                    >
                        <div className="p-0 h4 m-0">Save</div>
                    </div>
                </div>
            </div>
        </div>
    );

    const currentDisplayPopUp = useSelector(selectDisplayPopUp);
    return currentDisplayPopUp == CREATE_NEW_POPUP ? render : "";
};

export default CreateOverridePopUp;
