import React from 'react'
import { useSelector } from 'react-redux';
import axios from '../../../axios/axios';
import { selectSelectedCustomer } from '../../../rdx/slices/customerSlice';
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'

const CSVUploadPopUp = (props) => {


    const [file, setFile] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const {enqueueSnackbar} = useSnackbar();

    const customer = useSelector(selectSelectedCustomer);

    function uploadFile() {

        let form = new FormData();

        form.append("file", file);

        setLoading(true);

        
        axios.post(`/sounds/customer/${customer.id}/sound-task/csv`, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(() => {
                enqueueSnackbar("New Sound Tasks Uploaded.", { variant: "success", autoHideDuration: 3000 })
                props.onCloseClicked()
            }).catch((err => {
                enqueueSnackbar(err.response ? err.response.data : "ERROR UPLOADING CSV", { variant: "error", autoHideDuration: 5000 })
            }))
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className="popup">
            <div className="d-flex content card align-items-center justify-content-around p-5">

                <div className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle" style={{ height: "20px", width: "20px" }} onClick={() => props.onCloseClicked()}></div>

                <div className="row mt-2">
                    <label className={`btn-${!file ? "danger" : "success"} text-center`} htmlFor="upload">{file ? "Select Again" : "Click to Select a File"}</label>
                    <input id="upload" style={{ display: "none" }} type="file" name="name" onChange={(e) => {
                        setFile(e.target.files[0]);
                    }} />
                    <div className="mt-4 h2 text-center">{file ? `${file.name} (${(file.size / 1024).toFixed(2)}kB)` : "No File Selected..."}</div>
                </div>
                {!loading
                    ?
                    <div className="btn-success p-2" onClick={() => file ? uploadFile() : alert("No File Selected to Upload!")}>Upload CSV</div>
                    : <div className="btn-success p-2">Upload CSV</div>
                }


            </div>
        </div>
    );
}

CSVUploadPopUp.propTypes = {
    onCloseClicked: PropTypes.func.isRequired
}

export default CSVUploadPopUp;