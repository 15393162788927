import React from 'react';
import { PropTypes } from 'prop-types';
import Countdown from './Countdown';
import CallStatusIcon from './CallStatusIcon';
import AgentLabel from './AgentLabel';
import { DateTime } from 'luxon';

const LiveCallsTableRow = (props) => {
	const formatStart = (start) => {
		const dateTime = DateTime.fromMillis(start * 1000).setZone(
			'Europe/London'
		);
		return dateTime.toFormat('HH:mm:ss');
	};

	const getCallDirectionIcon = (direction) => {
		let className = '';
		switch (direction) {
			case 'OUTBOUND':
				className = 'fe fe-phone-outgoing';
				break;
			case 'INBOUND':
				className = 'fe fe-phone-incoming';
				break;
			case 'INTERNAL':
				className = 'fe fe-user';
				break;
			default:
		}
		return <span className={className} />;
	};

	return (
		<tr>
			<td
				height='55px'
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					display: 'flex',
				}}
			>
				{getCallDirectionIcon(props.data.callDirection)}{' '}
			</td>
			<td>
				<CallStatusIcon status={props.data.callStatus} />{' '}
			</td>
			<td>{formatStart(props.data.start)}</td>
			<td>{props.data.service}</td>
			<td>{props.data.callStage}</td>
			<td>{props.data.callType}</td>
			<td>
				{props.data.customerNo == ''
					? 'Withheld'
					: props.data.customerNo}
			</td>
			<td>{props.data.dialledNo}</td>
			<td>
				<Countdown time={props.data.start} />
			</td>
			<td>
				<AgentLabel agent={props.data.agent} />
				{props.data.agent}
			</td>
		</tr>
	);
};

LiveCallsTableRow.propTypes = { data: PropTypes.object.isRequired };

export default LiveCallsTableRow;
