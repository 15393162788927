import { configureStore } from '@reduxjs/toolkit';
import activeCallsReducer from './slices/activeCallsSlice';
import customersReducer from '../rdx/slices/customerSlice';
import authReducer from './slices/authSlice';
import queueReducer from './slices/queueSlice';
import soundsReducer from './slices/soundsSlice';
import soundsTasksReducer from './slices/soundTasksSlice';
import compsSliceReducer from './slices/compSlice';
import adminNumbersReducer from './slices/numbersSlice'
import temporaryOverridesReducer from '../components/temporary-override/temporaryOverridesSlice'
const store = configureStore({
    reducer: {
        activeCalls: activeCallsReducer,
        customers: customersReducer,
        authSlice: authReducer,
        queues: queueReducer,
        sounds: soundsReducer,
        soundTasks: soundsTasksReducer,
        comps: compsSliceReducer,
        adminNumbers: adminNumbersReducer,
        temporaryOverrides: temporaryOverridesReducer
    }
});

export default store;