import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const getSounds = createAsyncThunk(
  "sounds/getSoundsOfCustomer",
  async (customerId) => {
    const url = `/sounds/customer/${customerId}/sounds`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
);


const soundsSlice = createSlice({
  name: "sounds",
  initialState: {
    soundsState: {error:null,sounds:[],loading:false},
    soundFileState:{error:null,file:null,loading:false}
  },
  reducers:{
    setSounds : (state) =>{
      state.soundsState={error:null,sounds:[],loading:false}
    }
  },
  extraReducers: {
    [getSounds.fulfilled]: (state, action) => {
      state.soundsState={error:null,sounds:action.payload,loading:false}
    },
    [getSounds.rejected]: (state, action) => {
      console.error("Could not get customer sounds. :",action.payload)
      state.soundsState ={sounds:[],error:"We could not get the sounds for customer.",loading:false}
    },
    [getSounds.pending]: (state) => {
      state.soundsState ={...state.soundsState,loading:true}
    }
  },
});

export default soundsSlice.reducer;
export const {clearActiveCallData} = soundsSlice.actions;
export const selectSounds = (state) => state.sounds.soundsState.sounds?state.sounds.soundsState.sounds:[];
export const selectSoundsState = (state) => state.sounds.soundsState;

