import React from 'react';
import PropTypes from 'prop-types';

const PillIcon = (props) => {

	let className = `badge badge-pill bg-${props.status}-soft`;

	return <span className={className}>{props.text}</span>;
};

PillIcon.propTypes = {
	status: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default PillIcon;
