import { colours } from './colours';

const getColourViaHash = (toHashForColour) => {

    const hashed = hashString(toHashForColour);
    const idx = Math.round((hashed/2147483647)*toHashForColour.length)
    return colours[idx];
}

const hashString = (input) => {
    var hash = 0, i, chr;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

export default getColourViaHash;