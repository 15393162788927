import Axios from 'axios';

// let baseUrl = process.env.REACT_APP_API_BASE_URL;
let baseUrl = process.env.REACT_APP_API_BASE_URL;

const axios = Axios.create({
	baseURL: baseUrl,
	withCredentials: true,
});

axios.interceptors.request.use((req) => {
	req.headers['X-CSRF-TOKEN'] = sessionStorage.getItem("csrf")
	return req;
});

axios.interceptors.response.use(
	(res) => {
		sessionStorage.setItem("csrf",res.headers['x-csrf-token'])
		return res;
	},
	(err) => {
		console.table(err.response.headers)
		if(err.response.headers['x-csrf-token']){
			sessionStorage.setItem("csrf",err.response.headers['x-csrf-token'])
		}
		
		if (err.response.status == 401 && window.location.pathname !== "/login") {
			window.location = "/login"
		}
		return Promise.reject(err);
	}
);

export default axios;
