import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectSelectedCustomer,
	selectUsersCustomers,
	setSelectedCustomer,
} from '../../../rdx/slices/customerSlice';
import { sortByNameField } from '../../../utils/sortUtils';

const CustomerSelector = () => {
	const dispatch = useDispatch();

	const customersForSelection = useSelector(selectUsersCustomers);
	const selectedCustomer = useSelector(selectSelectedCustomer);

	const { enqueueSnackbar } = useSnackbar();

	const handleSelectCustomerDropDownEvent = (event) => {
		//Handle if they are a customer without queues enabled and on the queues page...

		const targetCustomer = customersForSelection.filter(
			(it) => it.id == event.target.value
		)[0];
		const value = event.target.value;

		if (location.pathname == '/queues') {
			if (targetCustomer.queues) {
				dispatch(setSelectedCustomer(value));
			} else {
				enqueueSnackbar(
					'The customer does not have queues set as viewable.',
					{ variant: 'warning' }
				);
			}
		} else {
			dispatch(setSelectedCustomer(value));
		}
	};

	let customers = JSON.parse(JSON.stringify(customersForSelection));

	customers = customers.map((customer) => {
		if (!customers.find((c) => customer.parentId === c.id)) {
			customer.parentId = null;
		}
		return customer;
	});

	const sorted = (customers) =>
		customers ? sortByNameField(Array.from(customers)) : [];

	const options = [];

	const findCustomerChild = (arrays, customerId, indent) => {
		const children = sorted(
			arrays.filter((customer) => customer.parentId === customerId)
		);
		const filteredArray = arrays.filter((el) => !children.includes(el));
		return children.map((customer) => {
			options.push(
				formatOption(
					customer.id,
					customer.name,
					indent,
					customer.queues
				)
			);
			const grandChildren = findCustomerChild(
				filteredArray,
				customer.id,
				indent + 1
			);
			customer['children'] = grandChildren;
			return customer;
		});
	};

	const formatOption = (id, name, indent, queues) => {
		return (
			<option
				key={id}
				value={id}
				disabled={location.pathname == '/queues' && !queues}
			>
				{'\xa0'.repeat(indent)}
				{'>'.repeat(indent) + ' '}
				{name}
			</option>
		);
	};

	findCustomerChild(customers, null, 0);

	return (
		<Form>
			<Form.Group controlId='exampleForm.ControlSelect2'>
				{/* <Form.Label>Viewing</Form.Label> */}
				<Form.Control
					as='select'
					onChange={(event) =>
						handleSelectCustomerDropDownEvent(event)
					}
					value={selectedCustomer ? selectedCustomer.id : 0}
				>
					{options}
				</Form.Control>
			</Form.Group>
		</Form>
	);
};

export default CustomerSelector;
