import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';


const TableFilter = (props) => {

	const [selected,setSelected] = React.useState('');

	useEffect(()=>{
		setSelected(props.options[0]?props.options[0].label:'')
		return;
	},[])
	
	const divOpts = props.options.map((it,i)=>{
		return <option className='text-muted form-select' key = {i} value={it.value}>{it.label} </option>
	})

	function clearSelection(filter){
		props.onClearSelection(filter)
		setSelected('')
	}

	return (
		<th className='text-muted'>
			<div
				style={{
					display: 'flex',
					alignItems: 'center'
				}}
				
			>
				<select 
				value={selected}
				onChange={(e)=>{
					props.onSelection({filter:props.filter,value:e.target.value})
					setSelected(e.target.value)
					
				}}
				className='tableFilter text-muted form-select-sm '
				>
					<option></option>
				{divOpts}
				</select>
				<div onClick={()=>clearSelection(props.filter)}> X</div>
			</div>
		</th>
	);
};

TableFilter.propTypes = {
	options: PropTypes.array.isRequired,
	onSelection: PropTypes.func.isRequired,
	onClearSelection:PropTypes.func.isRequired,
	filter:PropTypes.string.isRequired
};

export default TableFilter;
