import React from 'react';
import PropTypes from 'prop-types';

const CallStatusIcon = (props) => {
	let className = '';
	switch (props.status) {
		case 'RINGING':
			className = 'badge badge-pill bg-warning-soft';
			break;
		case 'CONNECTED':
			className = 'badge badge-pill bg-success-soft';
			break;
	}

	return <span className={className}>{props.status}</span>;
};

CallStatusIcon.propTypes = {
	status: PropTypes.string.isRequired,
};

export default CallStatusIcon;
