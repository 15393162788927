import React from 'react';
import { PropTypes } from 'prop-types';
import PillIcon from '../common/PillIcon'
import { DateTime } from 'luxon';
const CompetitionOverviewRow = (props) => {


	const startFormatted  =DateTime.fromSeconds(props.data.startTimeUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
	const endFormatted  =DateTime.fromSeconds(props.data.endTimeUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});

	return (
		<tr>
			<td>{props.data.competitionWindowId}</td>
			<td>{props.data.competitionName}</td>
			<td>{props.data.clientName}</td>
			<td>{props.data.totalEntries}</td>
			<td>{props.data.paidEntries}</td>
			<td>{props.data.freeEntries}</td>
			<td><PillIcon status={props.data.state=='LIVE'?'success':props.data.state=="COMPLETE"?"info":"warning"} text={props.data.state}></PillIcon></td>
			<td>{startFormatted}</td>
			<td>{endFormatted}</td>
			<td>
				<div onClick={()=>props.onDownloadClicked(props.data)} className="fe fe-download"></div>
			</td>
		</tr>
	);
};

CompetitionOverviewRow.propTypes = { 
	data: PropTypes.object.isRequired,
	onDownloadClicked: PropTypes.func.isRequired
};

export default CompetitionOverviewRow;
