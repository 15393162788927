export const sortByNameField = (items) => {

    items.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
    });

    return items;
}

export const sortByObjectProperty = (property, dir, items) => {
    items.sort((a, b) => {
        if (a[property] < b[property]) return dir == "ASC" ? -1 : 1;
        if (a[property] > b[property]) return dir == "ASC" ? 1 : -1;
        return 0;
    })
}