import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
//import mockData from "../../components/home/table/data.json"

export const getInboundCallsData = createAsyncThunk(
  "activeCalls/getInboundCallData",
  async (customerId) => {
    const url = `/dashboard/customers/${customerId}/activeCallsTable`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          //if(res)
          //resolve(mockData)
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
);

const activeCallsSlice = createSlice({
  name: "activeCalls",
  initialState: {
    calls: {error:null,calls:[],loading:false},
  },
  reducers:{
    clearActiveCallData : (state) =>{
      state.calls={error:null,calls:[],loading:false}
    }
  },
  extraReducers: {
    [getInboundCallsData.fulfilled]: (state, action) => {

      var sorted = action.payload.sort((a,b)=>a.start - b.start || a.customerNo - b.customerNo)

      state.calls ={calls:sorted,error:null,loading:false}    
    },
    [getInboundCallsData.rejected]: (state, action) => {
      console.error("Could not get Active Inbound Calls :",action.payload)
      state.calls ={calls:[],error:"We could not get the active calls.",loading:false}
    },
    [getInboundCallsData.pending]: (state) => {
      state.calls ={...state.calls,loading:true}
    },
  },
});

export default activeCallsSlice.reducer;
export const {clearActiveCallData} = activeCallsSlice.actions;
export const selectInboundCalls = (state) => state.activeCalls.calls.calls.filter(it=>it.callDirection==="INBOUND");
export const selectInboundCallsSize = (state) => state.activeCalls.calls.calls.filter(it=>it.callDirection==="INBOUND").length;
export const selectActiveCallsState = (state) => state.activeCalls.calls;

