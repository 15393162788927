import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";

import {
    getNumbersFromApi,
    selectAdminNumbers,
} from "../../rdx/slices/numbersSlice";
import { sortByObjectProperty } from "../../utils/sortUtils";
import TableHeader from "../common/TableHeader";
import NumbersRow from "./NumbersRow";

const NumbersTable = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = React.useState("");

    const initialPage = {
        size: 15,
        page: 0,
        sort: "number",
        dir: "ASC",
    };

    const [paging, setPaging] = React.useState(initialPage);

    const numbersState = useSelector(selectAdminNumbers, (oldStuff, newStuff) =>
        _.isEqual(oldStuff, newStuff)
    );

    useEffect(() => {}, [numbersState, paging]);

    useEffect(() => {
        if (search.match("^\\d{7,15}$")) {
            dispatch(getNumbersFromApi(search));
        }
    }, [search]);

    useEffect(() => {
        setPaging(initialPage);
    }, [numbersState]);

    function onSortHeader(sort) {
        if (paging.sort === sort) {
            setPaging({
                ...paging,
                sort: sort,
                dir: paging.dir == "ASC" ? "DESC" : "ASC",
            });
        } else {
            setPaging({ ...paging, sort: sort });
        }
    }

    var data = _.clone(numbersState.numbers ? numbersState.numbers : []);

    sortByObjectProperty(paging.sort, paging.dir, data ? data : []);

    data = data.slice(
        paging.page * paging.size,
        (paging.page + 1) * paging.size
    );

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => setPaging({ ...paging, page: pageNumber })}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        for (
            var i = Math.max(paging.page - 5, 0);
            i <
            Math.min(
                Math.max(paging.page - 5, 0) + 10,
                numbersState.numbers.length / paging.size
            );
            i++
        ) {
            const active = paging.page === i;
            render.push(formatPageElement(i, active));
        }

        return render;
    };

    return (
        <div>
            <div className="container-fluid tableContainer position-relative">
                <div className="card" style={{ overflow: "auto" }}>
                    <div className="card-header">
                        <div className="row align-items-start justify-start">
                            <div className="col-auto mt-2">
                                <h3 className="card-header-title">
                                    PBX Numbers{" "}
                                </h3>
                            </div>
                            <div className="col-auto">
                                <input
                                    placeholder="Number"
                                    className={`${
                                        search.match("^\\d{7,15}$")
                                            ? "text-success border-success"
                                            : "text-danger border-danger"
                                    } form-control p-1`}
                                    onChange={(event) => {
                                        setSearch(event.target.value);
                                    }}
                                    value={search}
                                />
                            </div>
                            <div
                                className={`col-auto spinner-border text-dark ${
                                    numbersState.loading ? "d-flex" : "d-none"
                                }`}
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                            <div
                                className={`col-auto text-danger ${
                                    numbersState.error ? "d-flex" : "d-none"
                                }`}
                                role="status"
                            >
                                <span className="">{numbersState.error}</span>
                            </div>
                        </div>
                    </div>
                    <Table
                        className="table table-sm table-nowrap card-table"
                        responsive="sm"
                    >
                        <thead>
                            <tr>
                                <TableHeader
                                    id={"number"}
                                    name="NUMBER"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"customerName"}
                                    name="CUSTOMER"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"description"}
                                    name="DESCRIPTION"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"routingType"}
                                    name="ROUTING TYPE"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"routeTo"}
                                    name="ROUTETO"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"className"}
                                    name="CLASS NAME"
                                    onSort={onSortHeader}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                ? data.map((it) => (
                                      <NumbersRow
                                          key={`${it.number}`}
                                          data={it}
                                      />
                                  ))
                                : ""}
                        </tbody>
                    </Table>
                    <div className="card-footer d-flex justify-content-between">
                        {!paging.isFirst ? (
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className={`page-link ps-4 pe-0 border-start ${
                                            paging.page > 0
                                                ? "d-flex"
                                                : "d-none"
                                        }`}
                                        onClick={() =>
                                            setPaging({
                                                ...paging,
                                                page: paging.page - 1,
                                            })
                                        }
                                    >
                                        <i className="fe fe-arrow-left me-1"></i>{" "}
                                        Prev
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                            {pageElements()}
                        </ul>
                        {!paging.isLast ? (
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className={`page-link ps-4 pe-0 border-start ${
                                            numbersState.numbers.length /
                                                paging.size -
                                                1 >
                                            paging.page
                                                ? "d-flex"
                                                : "d-none"
                                        }`}
                                        onClick={() =>
                                            setPaging({
                                                ...paging,
                                                page: paging.page + 1,
                                            })
                                        }
                                    >
                                        Next{" "}
                                        <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NumbersTable;
