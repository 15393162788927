import React from "react";
import { PropTypes } from "prop-types";

const NumbersRow = (props) => {
  return (
    <tr>
      <td>{props.data.number}</td>
      <td>{props.data.customerName}</td>
      <td>{props.data.description}</td>
      <td>{props.data.routingType}</td>
      <td>{props.data.routeTo}</td>
      <td>{props.data.className}</td>
    </tr>
  );
};

NumbersRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NumbersRow;
