import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { selectSounds } from "../../../rdx/slices/soundsSlice";
import _ from 'underscore';
import { Table } from 'react-bootstrap';
import TableHeader from '../../common/TableHeader';
import SoundsTableRow from './SoundsTableRow';
import { sortByObjectProperty } from '../../../utils/sortUtils';
import axios from '../../../axios/axios';
import { selectSelectedCustomer } from '../../../rdx/slices/customerSlice';


const SoundsTable = () => {

    const data = useSelector(selectSounds, _.isEqual);

    const selectedCustomer = useSelector(selectSelectedCustomer);

    const [sorting, setSorting] = React.useState({ field: "id", dir: "ASC" });

    const applySort = (field) => {
        if (sorting.field == field) {
            setSorting({ ...sorting, dir: sorting.dir == "ASC" ? "DESC" : "ASC" })
        } else {
            setSorting({ field: field, dir: "ASC" })
        }
    }

    const [pagination, setPagination] = useState({
        size: 10,
        page: 0,
        max_page: 0
    });

    useEffect(() => {
        setPagination({ ...pagination, max_page: Math.ceil(data.length / pagination.size) })
    }, [data])

    const cloned = _.clone(data)

    sortByObjectProperty(sorting.field, sorting.dir, cloned)

    const pageOfData = cloned.splice(pagination.page * pagination.size, pagination.size)

    function downloadSound(sound) {
        axios.get(`/sounds/customer/${selectedCustomer.id}/sounds/${sound.id}`,
            { withCredentials: true, responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `sound_${sound.id}.wav`);
                document.body.appendChild(link);
                link.click();


            }).catch((err) => {
                alert(JSON.stringify(err))
            })
    }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? 'active' : ''}
                    onClick={() =>
                        setPagination({ ...pagination, page: pageNumber })
                    }
                >
                    <button className='page'>
                        {pageNumber + 1}
                    </button>
                </li>
            );
        };

        for (var i = Math.max(pagination.page-5,0); i < Math.min(pagination.max_page,pagination.page+5); i++) {
            const active = pagination.page === i;
            render.push(formatPageElement(i, active));
        }

        return render;
    };
    return (<div className='container-fluid tableContainer col-12'>

        <div className='card' style={{ overflow: 'auto' }}>
            <div className='card-header'>
                <div className='row align-items-center'>
                    <div className='col-6'>
                        <h3 className='card-header-title'>
                            Customer Sounds
                        </h3>
                    </div>
                </div>
            </div>
            <Table
                className='table table-sm table-nowrap card-table'
                responsive='sm'
            >
                <thead>
                    <tr>
                        <TableHeader
                            id={'id'}
                            name='SOUND ID'
                            onSort={(id) => applySort(id)}
                        />
                        <TableHeader
                            id={'name'}
                            name='SOUND NAME'
                            onSort={(id) => applySort(id)}
                        />
                        <TableHeader
                            id={'description'}
                            name='SOUND DESCRIPTION'
                            onSort={(id) => applySort(id)}
                        />
                        <TableHeader
                            id={'shared'}
                            name='SHARED'
                            onSort={(id) => applySort(id)}
                        />
                        <TableHeader
                            id={'uploaded'}
                            name='UPLOADED'
                            onSort={(id) => applySort(id)}
                        />
                        <th className=" d-flex align-content-center justify-content-center">
                            MEDIA
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {pageOfData.map((d, i) => (
                        <SoundsTableRow
                            key={`${i}-${d.id}`}
                            data={d}
                            onDownloadClicked={(sound) => {
                                downloadSound(sound)
                            }}
                        />
                    ))}
                </tbody>
            </Table>
            <div className='card-footer d-flex justify-content-between'>
                {pagination.page > 0 ? (
                    <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                        <li className='page-item'>
                            <button
                                className='page-link ps-0 pe-4 border-end'
                                onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
                            >
                                <i className='fe fe-arrow-left me-1'></i>{' '}
                                Prev
                            </button>
                        </li>
                    </ul>
                ) : (
                    <ul></ul>
                )}
                <ul className='list-pagination pagination pagination-tabs card-pagination'>
                    {pageElements()}
                </ul>
                {pagination.page < pagination.max_page - 1 ? (
                    <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                        <li className='page-item'>
                            <button
                                className='page-link ps-4 pe-0 border-start'
                                onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
                            >
                                Next{' '}
                                <i className='fe fe-arrow-right ms-1'></i>
                            </button>
                        </li>
                    </ul>
                ) : (
                    <ul></ul>
                )}
            </div>
        </div>
    </div>)

}

export default SoundsTable;