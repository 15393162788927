import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import { selectSelectedCompBrand } from "../../rdx/slices/compSlice";
import TableHeader from "../common/TableHeader";
import CompCSVDownloadPopUp from "./CompCSVDownloadPopUp";
import CompetitionOverviewRow from "./CompetitionOverviewRow";

const CompetitionOverviewTable = () => {
    const [data, setData] = React.useState([]);

    const [sortBy, setSortyBy] = React.useState({
        sort: "startTime",
        dir: "DESC",
    });

    const [paging, setPaging] = React.useState({
        page: 0,
        isFirst: true,
        isLast: false,
        totalPages: 0,
    });

    const [downloadPopUp, setDownloadPopUp] = React.useState(null);

    const selectedBrand = useSelector(selectSelectedCompBrand);

    useEffect(() => {}, []);

    function getData(page) {
        if (selectedBrand) {
            axios
                .get(
                    `/competitions/brand/${selectedBrand.id}/overview?size=10&page=${page}&sort=${sortBy.sort},${sortBy.dir}`
                )
                .then((ok) => {
                    //const data = ok.data.response;
                    setData(ok.data.response.content);
                    setPaging({
                        ...paging,
                        page: ok.data.response.number,
                        isFirst: ok.data.response.first,
                        isLast: ok.data.response.last,
                        totalPages: ok.data.totalPages,
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }
    useEffect(() => {
        getData();
    }, [selectedBrand]);

    useEffect(() => {
        if (sortBy.sort && selectedBrand !== null) {
            getData();
        }
    }, [sortBy]);

    useEffect(() => {}, [data]);

    function onSortHeader(it) {
        if (sortBy.sort == it) {
            setSortyBy({ sort: it, dir: sortBy.dir == "ASC" ? "DESC" : "ASC" });
        } else {
            setSortyBy({ sort: it, dir: "DESC" });
        }
    }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => getData(pageNumber)}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        for (
            var i = Math.max(paging.page - 5, 0);
            i < Math.min(Math.max(paging.page - 5, 0) + 10, paging.totalPages);
            i++
        ) {
            const active = paging.page === i;
            render.push(formatPageElement(i, active));
        }

        return render;
    };

    return (
        <div>
            {downloadPopUp ? (
                <CompCSVDownloadPopUp
                    data={downloadPopUp}
                    onClose={() => {
                        setDownloadPopUp(null);
                    }}
                ></CompCSVDownloadPopUp>
            ) : (
                ""
            )}
            <div className="container-fluid tableContainer">
                <div className="card" style={{ overflow: "auto" }}>
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="card-header-title">
                                    Competitions Overview
                                </h3>
                            </div>
                        </div>
                    </div>

                    <Table
                        className="table table-sm table-nowrap card-table"
                        responsive="sm"
                    >
                        <thead>
                            <tr>
                                <TableHeader
                                    id={"compWindowId"}
                                    name="COMPETITION ID"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"compName"}
                                    name="NAME"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"clientName"}
                                    name="CLIENT"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"totalEntries"}
                                    name="ENTRIES"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"paidEntries"}
                                    name="PAID"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"freeEntries"}
                                    name="FREE"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"state"}
                                    name="STATUS"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"startTime"}
                                    name="STARTED"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"endTime"}
                                    name="ENDED"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"actions"}
                                    name="actions"
                                ></TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((it) => (
                                <CompetitionOverviewRow
                                    key={`${it.competitionId}-${it.competitionWindowId}-${it.clientId}`}
                                    data={it}
                                    onDownloadClicked={(data) =>
                                        setDownloadPopUp(data)
                                    }
                                />
                            ))}
                        </tbody>
                    </Table>
                    <div className="card-footer d-flex justify-content-between">
                        {!paging.isFirst ? (
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-0 pe-4 border-end"
                                        onClick={() => getData(paging.page - 1)}
                                    >
                                        <i className="fe fe-arrow-left me-1"></i>{" "}
                                        Prev
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                            {pageElements()}
                        </ul>
                        {!paging.isLast ? (
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-4 pe-0 border-start"
                                        onClick={() => getData(paging.page + 1)}
                                    >
                                        Next{" "}
                                        <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompetitionOverviewTable;
