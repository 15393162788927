import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import PillIcon from '../../common/PillIcon';
import { useSelector } from 'react-redux';
import { selectSelectedCustomer } from '../../../rdx/slices/customerSlice';
import axios from '../../../axios/axios';

const SoundsTableRow = (props) => {

    const hasUploaded = props.data.uploaded == "1";

    const customer = useSelector(selectSelectedCustomer)

    const [loading, setLoading] = React.useState(false);

    const [audioState, setAudioState] = React.useState({ sound: null, state: "STOPPED" })

    const downloadBtn = <div className={`fe fe-download d-flex`} style={{ minHeight: "30px", height: "100%", opacity: hasUploaded ? 1 : 0.3 }} />
    const playBtn = <div className={`fe fe-play`} style={{ minHeight: "30px", opacity: hasUploaded ? 1 : 0.3 }} />
    const stopBtn = <div className={`fe fe-pause`} style={{ minHeight: "30px", opacity: hasUploaded ? 1 : 0.3 }} />

    const audioPlayer = document.getElementById("WavPlayer");


    function playSound(sound) {

        const audioElement = document.getElementById("WavPlayer");

        if (audioElement.getAttribute("sound") === sound.id.toString()) {
            audioElement.play()
        } else {
            setLoading(true)
            axios.get(`/sounds/customer/${customer.id}/sounds/${sound.id}`,
                { withCredentials: true, responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.setAttribute("sound", sound.id)
                    link.href = url;
                    document.getElementById("WavPlayer").setAttribute("sound", sound.id)
                    document.getElementById("WavPlayer").setAttribute("src", url)
                    document.getElementById("WavPlayer").play()
                }).catch((err) => {
                    alert(JSON.stringify(err))
                }).finally(() => {
                    setLoading(false);
                })
        }
    }

    useEffect(() => {

        const listenerPlaying = audioPlayer.addEventListener('playing', (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "PLAYING" })
        })

        const listenerEnded = audioPlayer.addEventListener('ended', (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "STOPPED" })
        })

        const listenerPaused = audioPlayer.addEventListener('pause', (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "STOPPED" })
        })


        return () => {
            audioPlayer.removeEventListener('playing', listenerPlaying);
            audioPlayer.removeEventListener('ended', listenerEnded);
            audioPlayer.removeEventListener('pause', listenerPaused);
        }
    }, [])


    return (
        <tr>
            <td name="sound_id">{props.data.id}</td>
            <td name="sound_name">{props.data.name}</td>
            <td name="sound_description">{props.data.description}</td>
            <td name="sound_shared">{props.data.shared}</td>
            <td name="sound_uploaded"><PillIcon status={hasUploaded ? "SUCCESS" : "WARNING"} text={hasUploaded ? "UPLOADED" : "NO AUDIO"}></PillIcon></td>
            <td style={{ paddingTop: "0px", paddingBottom: "0px" }}>

                <div className="row flex-grow-1" >
                    <div className="d-flex col-6 align-items-center justify-content-center" onClick={() => props.onDownloadClicked(props.data)}>
                        {downloadBtn}
                    </div>

                    {!loading ?
                        <div className="d-flex col-6 align-items-center justify-content-center"
                            onClick={() => {
                                if (audioState.state === "STOPPED") {
                                    playSound(props.data)
                                } else {
                                    audioPlayer.pause()
                                }
                                setAudioState(audioState.state == "PLAYING" ? "STOPPED" : "PLAYING")
                            }
                            }>
                            {audioState.state == "PLAYING" && props.data.id == audioState.sound.toString() ? stopBtn : playBtn}
                        </div>
                        : <div className="d-flex col-6 align-items-center justify-content-center">
                            <div className="spinner-border text-primary" style={{height:"20px",width:"20px"}} role="status">
                            </div>
                        </div>}
                </div>


            </td>
        </tr>
    );
};

SoundsTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    onDownloadClicked: PropTypes.func.isRequired
};

export default SoundsTableRow;
