import React from "react";
import StatsPage from "../common/StatsPage";
import CreateOverridePopUp from "./CreateOverridePopUp";
import EditOverridePopUp from "./EditOverridePopUp";
import TemporaryOverrideNumbersTable from "./TemporaryOverrideNumbersTable";
import Toolbar from "./toolbar/Toolbar";
const TemporaryOverrides = () => {
    let render = (
        <div>
            <CreateOverridePopUp></CreateOverridePopUp>
            <EditOverridePopUp></EditOverridePopUp>

            <div className="row pr-5">
                <Toolbar />
            </div>
            <div className="row">
                <TemporaryOverrideNumbersTable></TemporaryOverrideNumbersTable>
            </div>
        </div>
    );

    return <StatsPage title="Temporary Routing" pretitle="" render={render} />;
};

export default TemporaryOverrides;
