import React from 'react';
import { PropTypes } from 'prop-types';

const TableHeader = (props) => {
	return (
		<th className='text-muted'>
			<div
				style={{
					display: 'flex',
					alignItems: 'center'
				}}
				onClick={() => props.onSort(props.id, 'ASC')}
			>
				<div className={props.centered?`text-center w-100`:''}><span>{props.name}</span></div>
				{props.onSort?
				<div style={{ marginLeft: '5px', lineHeight: '7.5px' }}>
					<div className=''>▲</div>
					<div style={{height:'2.5px'}}></div>
					<div className=''>▼</div>
				</div>:''}
			</div>
		</th>
	);
};

TableHeader.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	onSort: PropTypes.func,
	centered:PropTypes.bool
};

export default TableHeader;
