import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../img/blacksquare_solid_mi.svg";
import { useSnackbar } from "notistack";
import axios from "../axios/axios";
import { useDispatch } from "react-redux";
import {
    getWhoAmI,
    setName,
    setRole,
    setPermissions,
} from "../rdx/slices/authSlice";
import hasRequiredPermission from "../utils/permissionUtil";

const SignIn = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWhoAmI());
    }, []);

    const history = useHistory();
    const [loginDetail, setLoginDetails] = useState({
        email: "",
        password: "",
    });

    const { enqueueSnackbar } = useSnackbar();

    const login = (event) => {
        event.preventDefault();
        dispatch(getWhoAmI())
            .then(() => {
                setTimeout(() => {
                    axios
                        .post("/login", loginDetail)
                        .then((ok) => {
                            dispatch(setRole(ok.data.role));
                            dispatch(setName(ok.data.name));
                            dispatch(setPermissions(ok.data.permissions));
                            if (
                                hasRequiredPermission(ok.data.permissions, [
                                    "ROLE_ADMIN",
                                    "VIEW_DASHBOARD",
                                ])
                            ) {
                                history.push("/");
                            } else if (
                                hasRequiredPermission(ok.data.permissions, [
                                    "VIEW_AGENTS",
                                ])
                            ) {
                                history.push("/agents");
                            } else if (
                                hasRequiredPermission(ok.data.permissions, [
                                    "SECURE_ASSIST_AGENT",
                                ])
                            ) {
                                history.push("/secureassist");
                            } else if (
                                hasRequiredPermission(ok.data.permissions, [
                                    "RECORDINGS",
                                ])
                            ) {
                                history.push("/recordings");
                            } else {
                                enqueueSnackbar(
                                    "Login was successful but you have no permissions.",
                                    { variant: "error", autoHideDuration: 3000 }
                                );
                            }
                        })
                        .catch((err) => {
                            enqueueSnackbar(
                                err.response
                                    ? err.response.data
                                    : "CONNECTION ERROR",
                                { variant: "error", autoHideDuration: 3000 }
                            );
                        }, 100);
                });
            })
            .catch((err) => {
                enqueueSnackbar(
                    err.response ? err.response.data : "CONNECTION ERROR",
                    { variant: "error", autoHideDuration: 3000 }
                );
            });

        //dispatch(loginThunk(loginDetail));
    };

    const loginDetailOnChange = (event) => {
        var key = event.target.name;
        var value = event.target.value;
        setLoginDetails({ ...loginDetail, [key]: value });
    };

    return (
        <div className="container" style={{ height: "100%" }}>
            <div
                className="row justify-content-center"
                style={{ flexGrow: "1", height: "100%" }}
            >
                <div className="col-12 col-md-5 col-xl-4 my-5 align-self-center">
                    <div className="login logoContainer">
                        <img src={logo} className="loginLogo" />
                    </div>{" "}
                    <p className="text-muted text-center loginText">
                        Welcome to the Mi telecom dashboard{" "}
                    </p>{" "}
                    <form>
                        <div className="form-group">
                            <label className="form-label"> Username </label>{" "}
                            <input
                                name="email"
                                type="text"
                                className="form-control"
                                placeholder="Username"
                                value={loginDetail.email}
                                onChange={loginDetailOnChange}
                            />{" "}
                        </div>{" "}
                        <div className="form-group">
                            <div className="row">
                                <div className="col">
                                    <label className="form-label">
                                        {" "}
                                        Password{" "}
                                    </label>{" "}
                                </div>{" "}
                            </div>{" "}
                            <div>
                                <input
                                    name="password"
                                    className="form-control"
                                    type="password"
                                    placeholder="Enter your password"
                                    value={loginDetail.password}
                                    onChange={loginDetailOnChange}
                                />{" "}
                            </div>{" "}
                        </div>{" "}
                        <button
                            id="LoginBtn"
                            className="btn btn-lg w-100 btn-primary mb-3"
                            onClick={login}
                        >
                            Sign in
                        </button>{" "}
                    </form>{" "}
                </div>{" "}
                <div className="needAssistance">
                    <p>
                        Need assistance ? Email{" "}
                        <a href="mailto: help@mi-telecom.co.uk">
                            {" "}
                            help @mi - telecom.co.uk{" "}
                        </a>{" "}
                    </p>{" "}
                </div>{" "}
            </div>{" "}
        </div>
    );
};

export default SignIn;
