import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { sortByObjectProperty } from "../../utils/sortUtils";
import { useDispatch, useSelector } from "react-redux";
import {
    getCompetitionsFromAPI,
    selectCompetitions,
    selectSelectedCompBrand,
    selectSelectedCompClient,
    selectSelectedCompetition,
    selectTimePeriod,
    setSelectedCompetition,
} from "../../rdx/slices/compSlice";
import _ from "underscore";
import { DateTime } from "luxon";

const CompetitionSelector = () => {
    const timePeriod = useSelector(selectTimePeriod);
    const dispatch = useDispatch();
    const options = useSelector(selectCompetitions, _.isEqual);
    const selectedComp = useSelector(selectSelectedCompetition, _.isEqual);
    const selectedBrand = useSelector(selectSelectedCompBrand, _.isEqual);
    const selectedClient = useSelector(selectSelectedCompClient, _.isEqual);

    useEffect(() => {
        if (selectedBrand !== null) {
            dispatch(
                getCompetitionsFromAPI({
                    timeWindow: timePeriod.value ? timePeriod.value : "TODAY",
                    brandId: selectedBrand.id,
                })
            );
        }
    }, [timePeriod, selectedBrand, selectedClient]);

    useEffect(() => {}, [options]);

    const formatOption = (comp) => {
        const startFormatted = DateTime.fromSeconds(comp.startAtUnix).toFormat(
            "DD HH:mm:ss ZZZZ",
            { zone: "Europe/London", locale: "en-GB" }
        );
        const endFormatted = DateTime.fromSeconds(comp.endAtUnix).toFormat(
            "DD HH:mm:ss ZZZZ",
            { zone: "Europe/London", locale: "en-GB" }
        );

        return (
            <option
                key={comp.competitionWindowId}
                value={comp.competitionWindowId}
                id={comp.competitionName}
            >
                {`${comp.competitionWindowId}: ${comp.competitionName} (${startFormatted} - ${endFormatted})`}
            </option>
        );
    };

    const comps = [];

    const copy = [...options];
    sortByObjectProperty("competitionWindowId", "ASC", copy);

    copy.forEach((client) => {
        comps.push(formatOption(client));
    });

    const handleSelection = (event) => {
        dispatch(setSelectedCompetition(event.target.value));
    };

    return (
        <Form>
            <Form.Group controlId="selecty">
                <Form.Control
                    as="select"
                    onChange={(event) => handleSelection(event)}
                    value={selectedComp ? selectedComp.compWindowId : null}
                >
                    {comps}
                </Form.Control>
            </Form.Group>
        </Form>
    );
};

export default CompetitionSelector;
