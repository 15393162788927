import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    getAvailableNumbers,
    getTable,
} from "./TemporaryOverrideAPI";

export const TOGGLE_STATE_DEFAULT = "DEFAULT";
export const TOGGLE_STATE_ALL_TOGGLED = "ALL_TOGGLED";
export const CREATE_NEW_POPUP = "CREATE_NEW_POPUP";
export const EDIT_POPUP = "EDIT_POPUP";

export const getTemporaryOverrideTable = createAsyncThunk(
    "temporaryOverrides/getTable",
    async (payload) => {
        return getTable(payload);
    }
);


export const getNumbersAvailable = createAsyncThunk(
    "temporaryOverrides/getNumbersAvailable",
    async (payload) => {
        return getAvailableNumbers(payload);
    }
);

const temporaryOverrideSlice = createSlice({
    name: "temporaryOverrides",
    initialState: {
        toggleState: TOGGLE_STATE_DEFAULT,
        temporaryOverrides: [],
        initialStateMap: [],
        stateChangesMap: [],
        loading: true,
        displayPopUp: null,
        numbers: [],
        editingTo: null,
        restResponse: null,
    },
    reducers: {
        setTemporaryOverrides: (state, action) => {
            state.temporaryOverrides = action.payload;
        },
        showPopUp: (state, action) => {
            state.displayPopUp = action.payload;
        },
        setEditingTo: (state, action) => {
            state.editingTo = action.payload;
            state.displayPopUp = EDIT_POPUP;
        },
        overrideAll: (state, action) => {
            const clone = _.cloneDeep(state.initialStateMap);
            clone.forEach((it) => (it.toggled = action.payload));
            state.stateChangesMap = clone;
        },
        toggleState: (state, action) => {
            const clone = _.cloneDeep(state.stateChangesMap);
            clone.forEach((it) => {
                if (it.id === action.payload) {
                    it.toggled = !it.toggled;
                }
            });
            state.stateChangesMap = clone;
        },
    },
    extraReducers: {
        [getTemporaryOverrideTable.pending]: (state) => {
            state.loading = true;
        },
        [getTemporaryOverrideTable.fulfilled]: (state, action) => {
            state.loading = false;
            state.temporaryOverrides = action.payload;

            const mapped = action.payload.map((it) => {
                return {
                    id: it.id,
                    toggled:
                        it.overrideDestination === it.currentDestination,
                };
            });

            state.initialStateMap = _.cloneDeep(mapped);
            state.stateChangesMap = _.cloneDeep(mapped);
        },
        [getTemporaryOverrideTable.rejected]: (state) => {
            state.loading = false;
            state.restResponse = {success:false,error:"The server is either not there or there is a network issue."}
        },
        [getNumbersAvailable.fulfilled]: (state, action) => {
            state.numbers = action.payload;
        },
    },
});

export default temporaryOverrideSlice.reducer;
export const {
    setTemporaryOverrides,
    overrideAll,
    toggleState,
    showPopUp,
    setEditingTo,
} = temporaryOverrideSlice.actions;

export const selectIsTOStateDifference = (state) =>{
    var allSame = true;
    state.temporaryOverrides.initialStateMap.forEach(it=>{
        const target = state.temporaryOverrides.stateChangesMap.filter(it2=>it2.id === it.id)[0];
        if(target.toggled!==it.toggled){
            allSame = false
        }
    })

    return !allSame;
}

export const selectStateChanges = (state) => state.temporaryOverrides.stateChangesMap;
export const selectStateChangeMap = (state) =>
    new Map(
        state.temporaryOverrides.stateChangesMap.map((it) => [
            it.id,
            it.toggled,
        ])
    );
export const selectInitialStateMap = (state) =>
    new Map(
        state.temporaryOverrides.initialStateMap.map((it) => [
            it.id,
            it.toggled,
        ])
    );
export const selectTemporaryOverrideState = (state) => state.temporaryOverrides;

export const selectTemporaryOverrides = (state) =>
    state.temporaryOverrides.temporaryOverrides;

export const selectDisplayPopUp = (state) =>
    state.temporaryOverrides.displayPopUp;

export const selectNumbers = (state) => state.temporaryOverrides.numbers;
export const selectEditingTo = (state) => state.temporaryOverrides.editingTo;
export const selectRest = (state) => state.restResponse;
