
import { PropTypes } from 'prop-types';
import React from 'react'


const TimePeriodSelector = (props) =>{

    const options = props.options;

    const [selected,setSelected] = React.useState(null);

    if(props.selected!==(selected?selected:"")){
        setSelected(props.selected)
    }

    const opts = options.map((opt)=>{
            return (<button key={opt.name} className={opt.value===(selected?selected.value:"")?"btn-primary m-1":"btn-secondary m-1"} 
            onClick={()=>{
                props.handler(opt) 
                setSelected(opt)}}
                >
                {opt.name}</button>);
    })

    return(<div className="pb-2">
      {opts}
    </div>)
}

export default TimePeriodSelector;

TimePeriodSelector.propTypes = {
    options: PropTypes.any.isRequired,
    handler: PropTypes.func.isRequired,
    selected: PropTypes.any
  };
