/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const SecureAssistIFrame = (props) =>{

    useEffect(() => {
      //console.log("New Me")
    
      return () => {
        
      }
    }, [props.saUri])
    

    return (<div style={{ width: "100%", height: "auto" }}>
    <iframe
        id={props.saUri.r}
        name={props.saUri.r}
        src={props.saUri.uri}
        width={"100%"}
        height={"600px"}
    ></iframe>
</div>)
}

export default SecureAssistIFrame;