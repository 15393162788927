import React, {  } from "react";
import "flatpickr/dist/themes/airbnb.css";
import { PropTypes } from "prop-types";

const Card = (props) => {
  return (
    <div className="col-12 col-lg-6 col-xl">
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="text-uppercase text-muted mb-2">{props.title}</h6>

              <span className="h2 mb-0"> {props.text != null ? props.text : '...'} </span>

              {props.tag ? <span className="badge bg-success-soft mt-n1"> {props.tag} </span> : null }
            </div>
            <div className="col-auto">
              <span className={`h2 fe ${props.icon} text-muted mb-0`}></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  text : PropTypes.number,
  title : PropTypes.string.isRequired,
  tag: PropTypes.string,
  icon:PropTypes.string.isRequired
}

export default Card;
