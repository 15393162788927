import axios from "axios";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import {useSnackbar} from "notistack"

const INPUTS = {
  SINGLE: "SINGLE",
  RANGE: "RANGE",
  LIST: "LIST",
};

const REQUIRED_INPUTS = {
  customerId: "",
  classId: "",
  description: ""
};

const NumberServiceUpdaterForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [inputType, setInputType] = useState(INPUTS.SINGLE);
  const [initialValues, setInitialValues] = useState({
    ...REQUIRED_INPUTS,
    phoneNumber: "",
  });

  const ValidationSchema = Yup.object(
    {
      classId: Yup.number()
        .typeError("* Enter a valid id")
        .min(1, "* Enter a valid id")
        .required("* Required"),
      customerId: Yup.number()
        .typeError("* Enter a valid id")
        .min(1, "* Enter a valid id")
        .required("* Required"),
        description: Yup.string(),
      phoneNumber: Yup.string().when("phoneNumberFrom", {
        is: (from) => !from,
        then: () => {
          if (inputType == INPUTS.LIST) {
            return Yup.array()
              .transform((value, originalValue) => originalValue.split(","))
              .of(
                Yup.string()
                  .matches("^[0-9]*$", "* All phone numbers have to be digits")
                  .matches("^0.*", "* All phone numbers must start with 0")
                  .length(11, "* All phone numbers must be 11 digits long.")
                  .trim()
              )
              .required("* Required");
          } else {
            return Yup.string()
              .matches("^[0-9]*$", "* Phone number has to be a number")
              .matches("^0.*", "* Must start with 0")
              .length(11, "* Phone number must be 11 digits long.")
              .trim()
              .required("* Required");
          }
        },
      }),
      phoneNumberStart: Yup.string().when("phoneNumber", {
        is: (number) => !number,
        then: Yup.string()
          .matches("^[0-9]*$", "* Phone number has to be a number")
          .length(11, "* Phone number must be 11 digits long.")
          .trim()
          .test({
            name: 'phoneNumberStart',
            message: '* Range start must be smaller than end',
            test: function (value) {
                return Number(value) < Number(this.parent.phoneNumberEnd)
            },
          })
          .required("* Required"),
        otherwise: Yup.string(),
      }),
      phoneNumberEnd: Yup.string().when("phoneNumber", {
        is: (number) => !number,
        then: Yup.string()
          .matches("^[0-9]*$", "*Phone number has to be a number")
          .length(11, "* Phone number must be 11 digits long.")
          .trim()
          .test({
            name: 'phoneNumberEnd',
            message: '* Range end must be larger than start',
            test: function (value) {
                return Number(value) > Number(this.parent.phoneNumberStart)
            },
          })
          .required("* Required"),
        otherwise: Yup.string(),
      })
    },
    [
      //Prevent Yup Cyclic reference errors
      ["phoneNumber", "phoneNumberStart"],
      ["phoneNumber", "phoneNumberEnd"],
    ]
  );

  let numberInputs = "";

  const onInputTypeChange = (event) => {
    const value = event.target.value;
    setInputType(value);
  };

  const onSubmit = (values, actions) => {
    const data = { ...values };
    let endpoint = "";

    switch (inputType) {
      case INPUTS.SINGLE:
        endpoint = "/api/admin/numberServiceUpdater/single";
        break;
      case INPUTS.RANGE:
        endpoint = "/api/admin/numberServiceUpdater/range";
        break;
      case INPUTS.LIST:
        data.phoneNumber = data.phoneNumber.split(",");
        endpoint = "/api/admin/numberServiceUpdater/list";
        break;
      default:
        break;
    }


    axios
      .post(endpoint, data)
      .then(() => {
        enqueueSnackbar("Successfully sent request.",{variant:"error",autoHideDuration:3000})
        actions.setSubmitting(false);
      })
      .catch((error) => {
        let msg = 'Something went wrong, please try again later or contact support.';
        if (error.response) {
          if(error.response.status <500) {
            msg = error.response.data;
          }
        }
        enqueueSnackbar(msg,{variant:"error",autoHideDuration:3000})
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    switch (inputType) {
      case INPUTS.SINGLE:
        setInitialValues({ ...REQUIRED_INPUTS, phoneNumber: "" });
        break;
      case INPUTS.RANGE:
        setInitialValues({
          ...REQUIRED_INPUTS,
          phoneNumberStart: "",
          phoneNumberEnd: "",
        });
        break;
      case INPUTS.LIST:
        setInitialValues({ ...REQUIRED_INPUTS, phoneNumber: "", isList: ""});
        break;
    }
  }, [inputType]);

  switch (inputType) {
    case INPUTS.SINGLE:
      numberInputs = (
        <Field name="phoneNumber">
          {({ field, form: { errors, touched } }) => (
            <Form.Row>
              <Form.Label>Number</Form.Label>
              <Form.Control
                {...field}
                placeholder="e.g. 0123567890"
                type="text"
                value={field.value || ""}
                className={
                  errors.phoneNumber &&
                  touched.phoneNumber &&
                  "border border-danger"
                }
              />
              <ErrorMessage
                name="phoneNumber"
                className="text-danger"
                component="div"
              />
            </Form.Row>
          )}
        </Field>
      );
      break;
    case INPUTS.RANGE:
      numberInputs = (
        <Form.Row>
          <Row>
            <Col xs={12} lg={6}>
              <Field name="phoneNumberStart">
                {({ field, form: { errors, touched } }) => (
                  <Form.Row>
                    <Form.Label>Range Start</Form.Label>
                    <Form.Control
                      {...field}
                      placeholder="e.g. 01111111111"
                      type="text"
                      value={field.value || ""}
                      className={
                        errors.phoneNumberStart &&
                        touched.phoneNumberStart &&
                        "border border-danger"
                      }
                    />
                    <ErrorMessage
                      name="phoneNumberStart"
                      className="text-danger"
                      component="div"
                    />
                  </Form.Row>
                )}
              </Field>
            </Col>
            <Col xs={12} lg={6}>
              <Field name="phoneNumberEnd">
                {({ field, form: { touched, errors } }) => (
                  <Form.Row>
                    <Form.Label>Range End</Form.Label>
                    <Form.Control
                      {...field}
                      placeholder="e.g. 0122222222"
                      value={field.value || ""}
                      type="text"
                      className={
                        errors.phoneNumberEnd &&
                        touched.phoneNumberEnd &&
                        "border border-danger"
                      }
                    />
                    <ErrorMessage
                      name="phoneNumberEnd"
                      className="text-danger"
                      component="div"
                    />
                  </Form.Row>
                )}
              </Field>
            </Col>
          </Row>
        </Form.Row>
      );
      break;
    case INPUTS.LIST:
      numberInputs = (
        <Field name="phoneNumber">
          {({ field, form: { errors, touched } }) => (
            <Form.Row>
              <Form.Label>Number List</Form.Label>
              <Form.Control
                {...field}
                as="textarea"
                placeholder="e.g. 0123567890,01234567891,01234567892"
                value={field.value || ""}
                className={
                  errors.phoneNumber &&
                  touched.phoneNumber &&
                  "border border-danger"
                }
              />
              <ErrorMessage
                name="phoneNumber"
                className="text-danger"
                component="div"
              />
            </Form.Row>
          )}
        </Field>
      );
      break;
    default:
      setInputType(INPUTS.SINGLE);
      break;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        errors,
      }) => {
        return (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Form.Group>
              <Form.Label>Input Type</Form.Label>
              <Form.Control as="select" onChange={onInputTypeChange}>
                <option value={INPUTS.SINGLE}>Single</option>
                <option value={INPUTS.RANGE}>Range</option>
                <option value={INPUTS.LIST}>List</option>
              </Form.Control>
            </Form.Group>
            {numberInputs}
            <Form.Row>
              <Form.Label>Customer ID</Form.Label>
              <Form.Control
                name="customerId"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                value={values.customerId}
                className={
                  errors.customerId &&
                  touched.customerId &&
                  "border border-danger"
                }
              />
              <ErrorMessage
                name="customerId"
                className="text-danger"
                component="div"
              />
            </Form.Row>
            <Form.Row>
              <Form.Label>Class ID</Form.Label>
              <Form.Control
                name="classId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.classId}
                type="text"
                className={
                  errors.classId && touched.classId && "border border-danger"
                }
              />
              <ErrorMessage
                name="classId"
                className="text-danger"
                component="div"
              />
            </Form.Row>
            <Form.Row>
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Optional"
                as="textarea"
                value={values.description}
                className={
                  errors.description &&
                  touched.description &&
                  "border border-danger"
                }
              />
              <ErrorMessage
                name="description"
                className="text-danger"
                component="div"
              />
            </Form.Row>
            <Form.Row className="mt-3">
              <Button type="submit">Submit</Button>
            </Form.Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NumberServiceUpdaterForm;
