import React from "react";
import { useSelector } from "react-redux";
import { selectAuthState } from "../rdx/slices/authSlice";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router";

/**
 * A side NavItem which forwads the app on click to the set pushTo
 * A preNav func can be used to return t/f with custom logic before navigating.
 * @param {permissions,pushTo,title,icon,opacity,preNav} props 
 * @returns 
 */
const SideNavItem = (props) => {
    const permissions = props.permissions;
    const authState = useSelector(selectAuthState);
    const history = useHistory();

    const hasRequiredPermission = authState.permissions
        ? authState.permissions.some((p) => {
              return permissions.includes(p);
          })
        : false;

    const render = hasRequiredPermission ? (
        <li className="nav-item dropend" style={{ opacity: 1 }}>
            <a
                className="nav-link"
                id={`ID_${props.title}`}
                title={props.title}
                onClick={() => {
                    let nav = true;
                    if (props.preNav) {
                        nav = props.preNav()
                    }
                    if(nav){
                        history.push(props.pushTo);
                    }
                }}
            >
                <i className={props.icon}></i>
            </a>
        </li>
    ) : (
        ""
    );

    return render;
};

SideNavItem.propTypes = {
    permissions: PropTypes.array.isRequired,
    pushTo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    opacity: PropTypes.number,
    preNav: PropTypes.func,
};

export default SideNavItem;
