import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const getClientsFromAPI = createAsyncThunk(
    "compSlice/getClients",
    async(brandId) => {
        const url = `/competitions/brand/${brandId}/clients`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
);


export const getBrandsFromAPI = createAsyncThunk(
    "compSlice/getBrands",
    async() => {
        const url = `/competitions/brands`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res.data.response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
);

export const getCompetitionsFromAPI = createAsyncThunk(
    "compSlice/getComps",
    async(args) => {
        const url = `/competitions/brand/${args.brandId}/select?timeWindow=${args.timeWindow}`;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
);

const compsSlice = createSlice({
    name: "comps",
    initialState: {
        brands:[],
        selectedBrand:null,
        clients: [],
        selectedClient: null,
        timePeriod: { name: "WEEK", value: "WEEK" },
        competitions: [],
        selectedCompetition: null,
        loading: false,
    },
    reducers: {
        setSelectedBrand: (state, action) => {
            state.selectedBrand = state.brands.filter(
                (it) => it.id == action.payload
            )[0];
        },
        setSelectedClient: (state, action) => {
            state.selectedClient = state.clients.filter(
                (it) => it.clientId == action.payload
            )[0];
        },
        setSelectedTimePeriod: (state, action) => {
            state.timePeriod = action.payload;
            state.selectedCompetition = null;
        },
        setSelectedCompetition: (state, action) => {
            state.selectedCompetition = state.competitions.filter(
                (it) => it.competitionWindowId == action.payload
            )[0];
        },
    },
    extraReducers: {
        [getBrandsFromAPI.fulfilled]: (state, action) => {
            if (state.selectedBrand==null) {
                state.selectedBrand = action.payload[0];
            }
            state.brands = action.payload;
            state.loading = false;
        },
        [getBrandsFromAPI.rejected]: (state, action) => {
            console.error("Could not get Brands :", action.payload);
            state.loading = false;
        },
        [getBrandsFromAPI.pending]: (state) => {
            state.loading = true;
        },
        [getClientsFromAPI.fulfilled]: (state, action) => {
            if (state.selectedClient == null) {
                state.selectedClient = action.payload.response[0];
            }
            state.clients = action.payload.response;
            state.loading = false;
        },
        [getClientsFromAPI.rejected]: (state, action) => {
            console.error("Could not get Clients :", action.payload);
            state.loading = false;
        },
        [getClientsFromAPI.pending]: (state) => {
            state.loading = true;
        },
        [getCompetitionsFromAPI.fulfilled]: (state, action) => {
            if (state.selectedCompetition == null) {
                state.selectedCompetition = action.payload.response[0];
            }
            state.competitions = action.payload.response;
            state.loading = false;
        },
        [getCompetitionsFromAPI.rejected]: (state, action) => {
            console.error("Could not get Comps :", action.payload);
            state.loading = false;
        },
        [getCompetitionsFromAPI.pending]: (state) => {
            state.loading = true;
        },
    },
});

export default compsSlice.reducer;
export const {
    setSelectedClient,
    setSelectedBrand,
    setSelectedTimePeriod,
    setSelectedCompetition,
} = compsSlice.actions;
export const selectCompBrands = (state) => state.comps.brands;
export const selectSelectedCompBrand = (state) => state.comps.selectedBrand;
export const selectCompClients = (state) => state.comps.clients;
export const selectSelectedCompClient = (state) => state.comps.selectedClient;
export const selectTimePeriod = (state) => state.comps.timePeriod;
export const selectCompetitions = (state) => state.comps.competitions;
export const selectSelectedCompetition = (state) => state.comps.selectedCompetition;
export const selectSelections = (state) => {
    return {
        client: state.comps.selectedClient,
        competition: state.comps.selectedCompetition,
    };
};