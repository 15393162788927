import React from "react";
import "flatpickr/dist/themes/airbnb.css";
import { PropTypes } from "prop-types";
import PillIcon from "../common/PillIcon";

const AgentStatusCard = (props) => {
    let status = "";
    switch (props.status) {
        case "Available":
            status = "success";
            break;
        case "Offline":
            status = "danger";
            break;
        case "Busy":
            status = "warning";
            break;
        case "Unknown":
            status = "secondary";
            break;
    }

    return (
        <div className="col-12 col-lg-4 col-xl-4 col-xxl-3">
            <div className="card">
                <div className="card-body p-3">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="row">
                                <h5 className="text-muted mb-0">AGENT</h5>
                            </div>

                            <div className="col d-flex justify-content-between">
                                <div className="row-6 h2 mb-0 mt-1">
                                    {props.name ? props.name : "..."}
                                </div>
                                <div className="row-6">
                                    <PillIcon
                                        status={status}
                                        text={props.status.toUpperCase()}
                                    ></PillIcon>
                                </div>
                            </div>
                            <h5
                                className="text-uppercase mb-2 mt-1"
                                style={{ fontWeight: 400 }}
                            >
                                {props.directLine != null
                                    ? props.directLine
                                    : "..."}
                            </h5>

                            {/* {props.tag ? <span className="badge bg-success-soft mt-n1"> {props.tag} </span> : null } */}
                        </div>
                        <div className="col-auto">
                            {/* <span className={`h2 fe ${props.icon} text-muted mb-0`}></span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AgentStatusCard.propTypes = {
    name: PropTypes.number,
    status: PropTypes.string.isRequired,
    directLine: PropTypes.string.isRequired,
};

export default AgentStatusCard;
