import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const getWhoAmI = createAsyncThunk("auth/whoAmI", async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        "/whoami"
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
})

const authSlice = createSlice({
  name: "auth",
  initialState: {
    role: null,
    permissions:[],
    name:null
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setPermissions:(state,action)=>{
      state.permissions = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    }
  },
  extraReducers: {
    [getWhoAmI.fulfilled]: (state, action) => {
      state.role = action.payload.role;
      state.name = action.payload.name;
      state.permissions = action.payload.permissions;

    }

  }
});

export default authSlice.reducer;
export const selectAuthState = (state) => state.authSlice;
export const selectPermissions = (state) => state.authSlice.permissions;
export const {setName,setRole,setPermissions} = authSlice.actions;
