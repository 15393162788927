import React, { useState } from "react";
import Collapse from "react-css-collapse";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { selectAuthState } from "../rdx/slices/authSlice";
import axios from "../axios/axios";
import { useSnackbar } from "notistack";
import { selectSelectedCustomer } from "../rdx/slices/customerSlice";
import SideNavItem from "./SideNavItem";

export default function Sidenav(props) {
    const [open, setOpen] = useState(false);

    const history = useHistory();
    const authState = useSelector(selectAuthState);
    const { enqueueSnackbar } = useSnackbar();

    const selectedCustomer = useSelector(selectSelectedCustomer);

    const logout = () => {
        axios
            .post("/logout")
            .then(() => {
                enqueueSnackbar("You Have Logged Out", {
                    variant: "success",
                    autoHideDuration: 3000,
                });

                history.push("/login");
            })
            .catch((err) => {
                enqueueSnackbar(
                    err.response ? err.response.data : "CONNECTION ERROR",
                    { variant: "error", autoHideDuration: 3000 }
                );
            });
    };

    return authState.permissions ? (
        <nav
            className="navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md navbar-light"
            id="sidebarSmall"
            {...props}
        >
            <div className="container-fluid">
                {/* Navbar toggler */}
                <button
                    className="navbar-toggler"
                    type="button"
                    aria-controls="sidebarCollapse"
                    aria-expanded={!!open}
                    aria-label="Toggle navigation"
                    onClick={() => setOpen(!open)}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* Navbar brand */}
                <div className="navbar-brand" onClick={() => history.push("/")}>
                    <div className="navbar-brand-img mx-auto" />
                </div>

                <div className="navbar-user d-md-none"></div>

                {/* Navbar collapse */}
                <Collapse
                    className="navbar-collapse"
                    id="sidebarCollapse"
                    isOpen={open}
                >
                    {/* Navbar nav */}

                    <ul className="navbar-nav">
                        <SideNavItem
                            permissions={["ROLE_ADMIN", "VIEW_DASHBOARD"]}
                            pushTo="/"
                            title="Dashboard"
                            icon={"fe fe-home"}
                        />

                        <li
                            className="nav-item dropend"
                            style={{
                                opacity: selectedCustomer
                                    ? selectedCustomer.queues
                                        ? 1
                                        : 0.3
                                    : 0.3,
                            }}
                        >
                            <SideNavItem
                                permissions={["ROLE_ADMIN", "VIEW_AGENTS"]}
                                pushTo="/agents"
                                title="Agents"
                                icon={"fe fe-user"}
                                preNav={() => {
                                    if (!selectedCustomer.queues) {
                                        enqueueSnackbar(
                                            `${selectedCustomer.name} does not have queue viewing enabled.`,
                                            {
                                                variant: "warn",
                                                autoHideDuration: 3000,
                                            }
                                        );
                                        return false;
                                    }
                                    return true;
                                }}
                            />
                            <SideNavItem
                                permissions={["ROLE_ADMIN", "VIEW_QUEUES"]}
                                pushTo="/queues"
                                title="Queues"
                                icon={"fe fe-users"}
                                preNav={() => {
                                    if (!selectedCustomer.queues) {
                                        enqueueSnackbar(
                                            `${selectedCustomer.name} does not have queue viewing enabled.`,
                                            {
                                                variant: "warn",
                                                autoHideDuration: 3000,
                                            }
                                        );
                                        return false;
                                    }
                                    return true;
                                }}
                            />
                        </li>

                        <SideNavItem
                            permissions={["ROLE_ADMIN", "VIEW_COMPETITIONS"]}
                            pushTo="/competitions"
                            title="Competitions"
                            icon={"fe fe-gift"}
                        />

                        <SideNavItem
                            permissions={["SOUNDS", "ROLE_ADMIN"]}
                            pushTo="/sounds"
                            title="Sounds"
                            icon={"fe fe-volume-2"}
                        />

                        <SideNavItem
                            permissions={["ROLE_ADMIN","RECORDINGS"]}
                            pushTo="/recordings"
                            title="Sounds"
                            icon={"fe fe-play"}
                        />

                        <SideNavItem
                            permissions={["TEMPORARY_OVERRIDES", "ROLE_ADMIN"]}
                            pushTo="/temporary-routing"
                            title="Temporary Routing"
                            icon={"fe fe-shuffle"}
                        />

                        {authState.permissions.includes("ROLE_ADMIN") ? (
                            <div className="dropdown-divider"></div>
                        ) : (
                            ""
                        )}
                        <SideNavItem
                            permissions={["SOUNDS", "ROLE_ADMIN"]}
                            pushTo="/admin"
                            title="Admin Panel"
                            icon={"fe fe-flag"}
                        />
                        <SideNavItem
                            permissions={["ROLE_ADMIN", "SECURE_ASSIST_AGENT"]}
                            pushTo="/secureassist"
                            title="Secure Assist"
                            icon={"fe fe-credit-card"}
                            preNav={() => {
                                //alert("Going to Payments!");
                                return true;
                            }}
                        />
                    </ul>
                    <div className="mt-auto"></div>
                    <div className="dropdown-divider"></div>
                    <ul className="navbar-nav">
                        <li
                            className="nav-item"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Log Out"
                        >
                            <a className="nav-link " onClick={() => logout()}>
                                <i className="fe fe-log-out"></i>
                                <span className="d-md-none">Log Out</span>
                            </a>
                        </li>
                    </ul>
                </Collapse>
            </div>
        </nav>
    ) : (
        ""
    );
}
