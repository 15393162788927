import React from 'react';
import { PropTypes } from 'prop-types';
import { formatSecondsToTime } from '../../../utils/formatUtils';

const QueuesInfoTableRow = (props) => {
	return (
		<tr>
			<td>{props.data.queueName}</td>
			<td>{props.data.assignedAgents}</td>
			<td>{props.data.liveAgents}</td>
			<td>{props.data.calls}</td>
			<td>{props.data.callsAnswered}</td>
			<td>{props.data.callsAbandoned}</td>
			<td>{formatSecondsToTime(props.data.totalTime)}</td>
			<td>{formatSecondsToTime(props.data.talkTime)}</td>
			<td>{formatSecondsToTime((props.data.averageHoldTime))}</td>
		</tr>
	);
};

QueuesInfoTableRow.propTypes = { data: PropTypes.object.isRequired };

export default QueuesInfoTableRow;
