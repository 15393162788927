import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import PillIcon from '../../common/PillIcon';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { selectSelectedCustomer } from '../../../rdx/slices/customerSlice';
import axios from '../../../axios/axios';

const SoundsTasksTableRow = (props) => {

    const hasUploaded = !["RQURIES_AUDIO_CREATION", "QUEUED"].includes(props.data.soundTaskState)
    const cancellable = !["QUEUED", "LIVE"].includes(props.data.soundTaskState);

    const customer = useSelector(selectSelectedCustomer)

    const [loading, setLoading] = React.useState(false);

    const [audioState, setAudioState] = React.useState({ sound: null, state: "STOPPED" })

    const audioPlayer = document.getElementById("WavPlayer");

    const downloadBtn = <div className={`fe fe-download d-flex`} style={{ minHeight: "30px", height: "100%", opacity: hasUploaded ? 1 : 0.3 }} />
    const playBtn = <div className={`fe fe-play`} style={{ minHeight: "30px", opacity: hasUploaded ? 1 : 0.3 }} />
    const stopBtn = <div className={`fe fe-pause`} style={{ minHeight: "30px", opacity: hasUploaded ? 1 : 0.3 }} />
    const cancelBtn = <div className={`fe fe-trash-2 cancelSTBtn d-flex`} style={{ minHeight: "30px", opacity: cancellable ? 1 : 0.3 }}></div>

    useEffect(() => {

        const listenerPlaying = audioPlayer.addEventListener('playing', (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "PLAYING" })
        })

        const listenerEnded = audioPlayer.addEventListener('ended', (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "STOPPED" })
        })

        const listenerPaused = audioPlayer.addEventListener('pause', (e) => {
            e.target;
            const sound = audioPlayer.getAttribute("sound");
            setAudioState({ sound: sound, state: "STOPPED" })
        })


        return () => {
            audioPlayer.removeEventListener('playing', listenerPlaying);
            audioPlayer.removeEventListener('ended', listenerEnded);
            audioPlayer.removeEventListener('pause', listenerPaused);
        }
    }, [])


    function playSound(sound) {

        const audioElement = document.getElementById("WavPlayer");

        if (audioElement.getAttribute("sound") === sound.id.toString()) {
            audioElement.play()
        } else {
            setLoading(true)
            axios.get(`/sounds/customer/${customer.id}/sound-task/${props.data.id}/sound`,
                { withCredentials: true, responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.setAttribute("sound", sound.id)
                    link.href = url;
                    document.getElementById("WavPlayer").setAttribute("sound", sound.id)
                    document.getElementById("WavPlayer").setAttribute("src", url)
                    document.getElementById("WavPlayer").play()
                }).catch((err) => {
                    alert(JSON.stringify(err))
                }).finally(() => {
                    setLoading(false);
                })
        }
    }

    let status = "";
    let text = ""


    switch (props.data.soundTaskState) {

        // REQUIRES_AUDIO_CREATION,
        // AUDIO_CREATION_FAILED,
        // AWAITING_LIVE,
        // UPLOAD_LIVE_FAILED,
        // LIVE,
        // RETIRED,
        // QUEUED,
        // CANCELLED;

        case "AWAITING_LIVE":
            status = "info"
            text = "Scheduled"
            break;
        case "LIVE":
            status = "success"
            text = "Live"
            break;
        case "REQUIRES_AUDIO_CREATION":
            status = "dark"
            text = "Needs Audio"
            break;
        case "QUEUED":
            status = "primary"
            text = "Processing"
            break;

        case "RETIRED":
            status = "dark"
            text = "Retired"
            break;
            
        case "CANCELLED":
            status = "dark"
            text = "Cancelled"
            break;

        default:
            status = "danger"
            text = "Failed"
            break;

    }

    return (
        <tr>
            <td name="task_id">{props.data.id}</td>
            <td name="sound_name">{props.data.soundName}</td>
            <td name="state"><PillIcon status={status} text={text}></PillIcon></td>
            {/* <td name="status_text">{props.data.statusText}</td> */}
        
            <td name="go_live_time_eu_lon">{DateTime.fromSeconds(props.data.goLiveTimeUnix).setLocale('gb').toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</td>
            <td name="content" style={{whiteSpace:'break-spaces', wordWrap:'break-word !important',minWidth: '150px',maxWidth:'600px'}}>{props.data.content.substring(0, 1000)}</td>
            <td name="go_live_time_eu_lon">{DateTime.fromSeconds(props.data.createdAtUnix).setLocale('gb').toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</td>
        
            <td style={{ paddingTop: "0px", paddingBottom: "0px" }}>

                <div className="row flex-grow-1" >
                    <div className="d-flex col-6 align-items-center justify-content-center" onClick={() => props.onDownloadClicked(props.data)}>
                        {downloadBtn}
                    </div>

                    {!loading ?
                        <div className="d-flex col-6 align-items-center justify-content-center"
                            onClick={() => {
                                if (audioState.state === "STOPPED") {
                                    playSound(props.data)
                                } else {
                                    audioPlayer.pause()
                                }
                                setAudioState(audioState.state == "PLAYING" ? "STOPPED" : "PLAYING")
                            }
                            }>
                            {audioState.state == "PLAYING" && props.data.id == audioState.sound.toString() ? stopBtn : playBtn}
                        </div>
                        : <div className="d-flex col-6 align-items-center justify-content-center">
                            <div className="spinner-border text-primary" style={{ height: "20px", width: "20px" }} role="status">
                            </div>
                        </div>}
                </div>


            </td>
            <td style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={() => {
                props.onCancelSoundTaskClicked(props.data)
            }}>
                {cancelBtn}
            </td>
        </tr>
    );
};

SoundsTasksTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    onDownloadClicked: PropTypes.func.isRequired,
    onCancelSoundTaskClicked: PropTypes.func.isRequired
};

export default SoundsTasksTableRow;
