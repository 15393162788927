import React from 'react';
import StatsPage from '../components/common/StatsPage';
import { useSelector } from 'react-redux';

import { selectSelectedCustomer } from '../rdx/slices/customerSlice';
import { selectSelectedQueue } from '../rdx/slices/queueSlice';
import QueuesInfoTable from '../components/home/table/QueuesInfoTable';
import QueueSelector from '../components/home/selector/QueueSelector';
import QueueInfoTable from '../components/home/table/QueueInfoTable';
import DoughnutChart from '../components/home/chart/DoughnutChart';
import TopCards from '../components/home/cards/TopCards';

const QueuesInfo = () => {
	const selectedCustomer = useSelector(selectSelectedCustomer);
	const selectedQueue = useSelector(selectSelectedQueue);


	const cards = [
        {
            field: "activeCalls",
            title: "ACTIVE INBOUND CALLS",
            tag: "LIVE",
            icon: "fe fe-phone-incoming",
        },
        {
            field: "onlineAgents",
            title: "ONLINE AGENTS",
            tag: "",
            icon: "fe fe-users ",
        },
        {
            field: "totalInboundCalls",
            title: "TOTAL INBOUND CALLS",
            tag: "",
            icon: "fe fe-phone-call",
        },
        {
            field: "missedCalls",
            title: "MISSED CALLS",
            tag: "",
            icon: "fe fe-phone-missed",
        },
    ];

	let render = selectedCustomer ? (
		<div>
			<TopCards url={`/queues/customers/${selectedCustomer.id}/cards`} cards={cards} timeInterval={5000} />
			<QueuesInfoTable />
			<div className="container-fluid">
			<div className='seperator'></div>
			</div>
			<div className='container-fluid'>
				<div className='row justify-content-between'>
					<div className='col-md-4 col-sm-12 align-self-center'>
						
						<h3> Select Queue</h3> <QueueSelector />
						
					</div>
				</div>
				{selectedQueue ? (
					<div className='row'>
						<div className='col-12 col-xl-8'>
							<QueueInfoTable />
						</div>
						<div className='col-12 col-xl-4'>
							<DoughnutChart />
						</div>
					</div>
				) : null}
			</div>
		</div>
	) : null;

	return (
		<StatsPage title='Queues Info' pretitle='Overview' render={render} />
	);
};

export default QueuesInfo;
