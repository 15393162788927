import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StatsPage from '../components/common/StatsPage';
import SoundsTable from '../components/sounds/tables/SoundsTable';
import SoundTasksTable from '../components/sounds/tables/SoundsTasksTable';
import CSVUploadPopUp from '../components/sounds/upload/CSVUploadPopUp';
import UploadSTPopUp from '../components/sounds/upload/UploadSTPopUp';
import { selectSelectedCustomer } from '../rdx/slices/customerSlice';
import { getSounds } from '../rdx/slices/soundsSlice';


const Sounds = () => {
	const dispatch = useDispatch();
	const selectedCustomer = useSelector(selectSelectedCustomer);

	const [showPopUp,setShowPopUp] = React.useState(null)

	let CSVPopUp = <CSVUploadPopUp
	onCloseClicked={()=>setShowPopUp(null)}></CSVUploadPopUp>

	function handleCSVUploadClicked(){
			setShowPopUp("CSV")
	}

	function handleUploadSTClicked() {
		setShowPopUp("ST")
	}


	useEffect(() => {
		if (selectedCustomer) {
			dispatch(getSounds(selectedCustomer.id))
		}
	}, [selectedCustomer]);

	const render = selectedCustomer ? (
		<div>
			{showPopUp?showPopUp=="CSV"?CSVPopUp:<UploadSTPopUp onCloseClicked={()=>setShowPopUp(null)}></UploadSTPopUp>:''}
			<SoundsTable></SoundsTable>
			<SoundTasksTable
			onCSVUploadClicked={()=>{
				handleCSVUploadClicked()
			}}
			onUploadClicked={()=>{
				handleUploadSTClicked()
			}}
			></SoundTasksTable>
		</div>
	) : null;

	return <StatsPage title='Sounds' pretitle='Overview' render={render} />;
};

export default Sounds;
