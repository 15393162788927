import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import QueuesInfoTableRow from './QueuesInfoTableRow';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectQueuesInfo,
	getQueuesInfoFromApi,
	selectSelectedQueue,
	setSelectedQueue,
} from '../../../rdx/slices/queueSlice';
import { selectSelectedCustomer } from '../../../rdx/slices/customerSlice';
import _ from 'underscore';
import TableHeader from '../../common/TableHeader';

const QueuesInfoTable = () => {
	const dispatch = useDispatch();
	const selectedCustomer = useSelector(selectSelectedCustomer, _.isEqual);
	const selectedQueue = useSelector(selectSelectedQueue, _.isEqual);
	const data = useSelector(selectQueuesInfo, _.isEqual);
	const dateTime = DateTime.now().setZone('Europe/London');
	const startDate = Math.floor(dateTime.startOf('day').toSeconds());
	const endDate = Math.floor(dateTime.endOf('day').toSeconds());
	const [pagination, setPagination] = useState({
		data: [],
		size: 10,
		page: 0,
		max_page: 0,
	});
	const [sortBy, setSortBy] = useState({
		fieldName: 'agentName',
		dir: 'DESC',
	});
	var dataCopy = JSON.parse(JSON.stringify(data));

	const onSortHeader = (id) => {
		if (sortBy.fieldName === id) {
			if (sortBy.dir == 'ASC') {
				setSortBy({ fieldName: id, dir: 'DESC' });
			} else {
				setSortBy({ fieldName: id, dir: 'ASC' });
			}
		} else {
			setSortBy({ fieldName: id, dir: 'ASC' });
		}
	};

	useEffect(() => {
		let getQueuesInfoInterval = null;
		if (selectedCustomer) {
			const payload = {
				customerId: selectedCustomer.id,
				startDate: startDate,
				endDate: endDate,
			};

			dispatch(getQueuesInfoFromApi(payload));
			getQueuesInfoInterval = setInterval(() => {
				dispatch(getQueuesInfoFromApi(payload));
			}, 2000);
		}
		return () => {
			clearInterval(getQueuesInfoInterval);
		};
	}, [selectedCustomer]);

	useEffect(() => {
		if (selectedQueue == null && data) {
			if (data.length > 0) {
				dispatch(setSelectedQueue(data[0].queueId));
			}
		}
	}, [data]);

	useEffect(() => {
		dataCopy = dataCopy.sort((o1, o2) => {
			if (o1[sortBy.fieldName] > o2[sortBy.fieldName])
				return sortBy.dir == 'ASC' ? 1 : -1;
			if (o1[sortBy.fieldName] == o2[sortBy.fieldName]) return 0;
			if (o1[sortBy.fieldName] < o2[sortBy.fieldName])
				return sortBy.dir == 'ASC' ? -1 : 1;
		});
		calculateMaxPageNumber();
		renderData();
	}, [data, pagination.size, pagination.page, sortBy]);

	const nextPageOnClick = () => {
		setPagination((prevState) => {
			return { ...prevState, page: prevState.page + 1 };
		});
	};

	const prevPageOnClick = () => {
		setPagination((prevState) => {
			return { ...prevState, page: prevState.page - 1 };
		});
	};

	const calculateMaxPageNumber = () => {
		const maxPage = data.length / pagination.size;
		setPagination((prevState) => {
			return { ...prevState, max_page: Math.ceil(maxPage) };
		});
	};

	const renderData = () => {
		const start = pagination.size * pagination.page;
		const end = pagination.size * (pagination.page + 1);
		setPagination((prevState) => {
			return { ...prevState, data: dataCopy.slice(start, end) };
		});
	};

	const pageElements = () => {
		let render = [];
		const formatPageElement = (pageNumber, active) => {
			return (
				<li
					key={`page-${pageNumber}`}
					className={active ? 'active' : ''}
					onClick={() =>
						setPagination({ ...pagination, page: pageNumber })
					}
				>
					<button className='page'>
						{pageNumber + 1}
					</button>
				</li>
			);
		};

		for (var i = 0; i < pagination.max_page; i++) {
			const active = pagination.page === i;
			render.push(formatPageElement(i, active));
		}

		return render;
	};

	return (
		<div className='container-fluid tableContainer'>
			<div className='card' style={{ overflow: 'auto' }}>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col'>
							<h3 className='card-header-title'>
								Queues Overview
							</h3>
						</div>
					</div>
				</div>
				<Table
					className='table table-sm table-nowrap card-table'
					responsive='sm'
				>
					<thead>
						<tr>
							<TableHeader
								id={'queueName'}
								name='QUEUE NAME'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'assignedAgents'}
								name='ASSIGNED AGENTS'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'liveAgents'}
								name='LIVE AGENTS'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'calls'}
								name='CALLS'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'callsAnswered'}
								name='CALLS ANSWERED'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'callsAbandoned'}
								name='CALLS ABANDONED'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'totalTime'}
								name='TOTAL TIME'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'talkTime'}
								name='TALK TIME'
								onSort={onSortHeader}
							/>
							<TableHeader
								id={'averageHoldTime'}
								name='AVG HOLD TIME'
								onSort={onSortHeader}
							/>
						</tr>
					</thead>
					<tbody>
						{pagination.data.map((d, i) => (
							<QueuesInfoTableRow
								key={`${d.customerNo}-${d.dialledNo}-${d.start}-${i}`}
								data={d}
							/>
						))}
					</tbody>
				</Table>
				<div className='card-footer d-flex justify-content-between'>
					{pagination.page > 0 ? (
						<ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
							<li className='page-item'>
								<button
									className='page-link ps-0 pe-4 border-end'
									onClick={() => prevPageOnClick()}
								>
									<i className='fe fe-arrow-left me-1'></i>{' '}
									Prev
								</button>
							</li>
						</ul>
					) : (
						<ul></ul>
					)}
					<ul className='list-pagination pagination pagination-tabs card-pagination'>
						{pageElements()}
					</ul>
					{pagination.page < pagination.max_page - 1 ? (
						<ul className='list-pagination-next pagination pagination-tabs card-pagination'>
							<li className='page-item'>
								<button
									className='page-link ps-4 pe-0 border-start'
									onClick={() => nextPageOnClick()}
								>
									Next{' '}
									<i className='fe fe-arrow-right ms-1'></i>
								</button>
							</li>
						</ul>
					) : (
						<ul></ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default QueuesInfoTable;
