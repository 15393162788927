import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimePeriodSelector from '../components/common/TimePeriodSelector';
import CompetitionBrandSelector from '../components/competitions/CompetitionBrandSelector';
import CompetitionClientSelector from '../components/competitions/CompetitionClientSelector';
import CompetitionsEntryTable from '../components/competitions/CompetitionEntriesTable';
import CompetitionOverviewTable from '../components/competitions/CompetitionOverviewTable';
import CompetitionSelector from '../components/competitions/CompetitionSelector';
import CompetitionStats from '../components/competitions/CompetitionStats';
import Date from '../components/home/date/Date';
import { getBrandsFromAPI, getClientsFromAPI, selectCompBrands, selectCompClients, selectTimePeriod, setSelectedTimePeriod } from '../rdx/slices/compSlice';


const Competitions = () => {

	const clients = useSelector(selectCompClients)

	const brands = useSelector(selectCompBrands)

	const timeOpts = [{ name: "TODAY", value: "TODAY" }, { name: "WEEK", value: "WEEK" }, { name: "THIS MONTH", value: "MONTH" }, { name: "YESTERDAY", value: "YESTERDAY" }, { name: "LAST WEEK", value: "LAST_WEEK" }, { name: "LAST MONTH", value: "LAST_MONTH" }];

	const dispatch = useDispatch()

	const selectedTimePeriod = useSelector(selectTimePeriod)

	useEffect(() => {
		dispatch(getClientsFromAPI())
		dispatch(getBrandsFromAPI())
	}, [])

	const render = (
		<div className="container-fluid">
				<div className='row'>
				<div className="col-4">
					<CompetitionBrandSelector brands={brands}></CompetitionBrandSelector>
				</div>
				<div className="col-4">
					<CompetitionClientSelector clients={clients}></CompetitionClientSelector>
				</div>

			</div>
			<TimePeriodSelector
				handler={(opt) => dispatch(setSelectedTimePeriod(opt))}
				options={timeOpts}
				selected={selectedTimePeriod}
			></TimePeriodSelector>
			<div className='row'>
				<div className="col-4">
					<CompetitionSelector></CompetitionSelector>
				</div>
			</div>

			<CompetitionStats></CompetitionStats>
			<CompetitionOverviewTable></CompetitionOverviewTable>
			<CompetitionsEntryTable></CompetitionsEntryTable>
		</div>
	)

	return <div className='main-content statsPage'>
		<div className='header'>
			<div className='container-fluid'>
				<div className='header-body'>
					<div className='row justify-content-between'>
						<div
							className='col-md-4 col-sm-12 align-self-center'
							//align='start'
						>
							<h6 className='header-pretitle'>Overview</h6>
							<h1 className='header-title'>MiWinner</h1>
						</div>
						<div className='col-md-4 col-sm-12 align-self-end'>
							<div className='row justify-content-around'>
								<div className='col-12' 
								//align='end'
								>
									<Date
										format={'cccc dd LLLL'}
										zone='Europe/London'
									></Date>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{render}
	</div>;
};

export default Competitions;
