import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import QueueInfoTableRow from "./QueueInfoTableRow";
import { DateTime } from "luxon";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    selectQueueInfo,
    selectSelectedQueue,
    getQueueInfoFromApi,
} from "../../../rdx/slices/queueSlice";
import { selectSelectedCustomer } from "../../../rdx/slices/customerSlice";
import _ from "underscore";
import TableHeader from "../../common/TableHeader";

const QueueInfoTable = () => {
    const data = useSelector(selectQueueInfo, _.isEqual);
    const dispatch = useDispatch();
    const selectedQueue = useSelector(selectSelectedQueue, shallowEqual);
    const selectedCustomer = useSelector(selectSelectedCustomer);
    const dateTime = DateTime.now().setZone("Europe/London");
    const startDate = Math.floor(dateTime.startOf("day").toSeconds());
    const endDate = Math.floor(dateTime.endOf("day").toSeconds());

    const [sortBy, setSortBy] = useState({
        fieldName: "agentName",
        dir: "DESC",
    });

    const onSortHeader = (id) => {
        if (sortBy.fieldName === id) {
            if (sortBy.dir == "ASC") {
                setSortBy({ fieldName: id, dir: "DESC" });
            } else {
                setSortBy({ fieldName: id, dir: "ASC" });
            }
        } else {
            setSortBy({ fieldName: id, dir: "ASC" });
        }
    };

    var dataCopy = JSON.parse(JSON.stringify(data));

    if (sortBy !== null) {
        dataCopy = dataCopy.sort((o1, o2) => {
            if (o1[sortBy.fieldName] > o2[sortBy.fieldName])
                return sortBy.dir == "ASC" ? 1 : -1;
            if (o1[sortBy.fieldName] == o2[sortBy.fieldName]) return 0;
            if (o1[sortBy.fieldName] < o2[sortBy.fieldName])
                return sortBy.dir == "ASC" ? -1 : 1;
        });
    }

    useEffect(() => {
        let getQueueInfoInterval = null;
        if (selectedQueue) {
            const payload = {
                customerId: selectedCustomer.id,
                queueId: selectedQueue.id,
                startDate: startDate,
                endDate: endDate,
            };

            dispatch(getQueueInfoFromApi(payload));
            getQueueInfoInterval = setInterval(() => {
                dispatch(getQueueInfoFromApi(payload));
            }, 2000);
        }
        return () => {
            clearInterval(getQueueInfoInterval);
        };
    }, [selectedQueue]);

    return (
        <div>
            <div className="card" style={{ overflow: "auto" }}>
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="card-header-title">
                                Queue Detailed Breakdown
                            </h3>
                        </div>
                    </div>
                </div>
                <Table
                    className="table table-sm table-nowrap card-table"
                    responsive="sm"
                >
                    <thead>
                        <tr>
                            <TableHeader
                                id={"agentName"}
                                name="AGENT NAME"
                                onSort={onSortHeader}
                            />
                            <TableHeader
                                id={"directLine"}
                                name="DIRECT LINE"
                                onSort={onSortHeader}
                            />
                            <TableHeader
                                id={"status"}
                                name="STATUS"
                                onSort={onSortHeader}
                            />
                            <TableHeader
                                id={"calls"}
                                name="CALLS"
                                onSort={onSortHeader}
                            />
                            <TableHeader
                                id={"missedCalls"}
                                name="MISSED CALLS"
                                onSort={onSortHeader}
                            />
                            <TableHeader
                                id={"totalTalkTime"}
                                name="TOTAL TALK TIME"
                                onSort={onSortHeader}
                            />
                            <TableHeader
                                id={"averageTalkTime"}
                                name="AVG TALK TIME"
                                onSort={onSortHeader}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {dataCopy.map((d, i) => (
                            <QueueInfoTableRow
                                key={`queue-info-table-row-${i}`}
                                data={d}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default QueueInfoTable;
