import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { overrideAll, selectStateChanges } from "../temporaryOverridesSlice";

const AllToggleBtn = () => {
    const stateChanges = useSelector(selectStateChanges);
    const dispatch = useDispatch();

    return (
        <label className="switch text-muted" style={{cursor:'pointer',color:"#00D97E"}}>
            <input
                type="checkbox"
                checked={Array.from(stateChanges).every((it) => it.toggled)}
                onChange={() => {
                    dispatch(
                        overrideAll(
                            !Array.from(stateChanges).every((it) => it.toggled)
                        )
                    );
                }}
            />
            <span className="slider round"></span>
        </label>
    );
};

export default AllToggleBtn;
