import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../axios/axios';

export const getQueuesForCustomerFromApi = createAsyncThunk(
	'queueSlice/getQueuesForCustomerFromApi',
	async (payload) => {
		const customerId  = payload;
		const url = `/queues/customers/${customerId}`;
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
);

export const getQueuesForCustomerFromApiAgentPage = createAsyncThunk(
	'queueSlice/getQueuesForCustomerFromApi',
	async (payload) => {
		const customerId  = payload;
		const url = `/agents/customer-queues/${customerId}`;
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
);

export const getQueuesInfoFromApi = createAsyncThunk(
	'queueSlice/getQueuesInfoFromApi',
	async (payload) => {
		const { customerId, startDate, endDate } = payload;
		const url = `/queues/customers/${customerId}/table?start=${startDate}&end=${endDate}`;
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
);

export const getQueueInfoFromApi = createAsyncThunk(
	'queueSlice/getQueueInfoFromApi',
	async (payload) => {
		const { customerId, queueId, startDate, endDate } = payload;
		const url = `/queues/customers/${customerId}/queue/${queueId}/table?start=${startDate}&end=${endDate}`;
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
);

const queueSlice = createSlice({
	name: 'queues',
	initialState: {
		customerQueues:[],
		queuesInfo: [],
		queueInfo: [],
		selectedQueue: null,
	},
	reducers: {
		setSelectedQueue: (state, action) => {
			
			state.selectedQueue = state.customerQueues.filter(
				(it) => it.id == action.payload
			)[0];
		},	
	},
	extraReducers: {
		[getQueuesForCustomerFromApi.fulfilled]: (state, action) => {
			
			state.customerQueues = action.payload;

			if(state.selectedQueue==null){
				state.selectedQueue = action.payload[0];
			}
			
		},
		[getQueuesInfoFromApi.fulfilled]: (state, action) => {
			state.queuesInfo = action.payload;
		},
		[getQueueInfoFromApi.fulfilled]: (state, action) => {
			state.queueInfo = action.payload;
		},
	},
})

export default queueSlice.reducer;
export const { setSelectedQueue } = queueSlice.actions;
export const selectQueuesInfo = (state) => state.queues.queuesInfo;
export const selectQueueInfo = (state) => state.queues.queueInfo;
export const selectSelectedQueue = (state) => state.queues.selectedQueue;
export const selectCustomerQueues = (state) => state.queues.customerQueues;
