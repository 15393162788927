import axios from "../../axios/axios";

export const overrideNumbers = (customerId, newToggledStates) => {
    const toacs = newToggledStates.map((it) => {
        return { temporaryOverrideId: it.id, active: it.new };
    });
    const req = { overrides: toacs };

    const path = `/temporary-override/${customerId}`;
    return new Promise((resolve, reject) => {
        axios
            .post(path + "/activate", req)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getAvailableNumbers = (customerId) => {
    const path = `/temporary-override/${customerId}`;
    return new Promise((resolve, reject) => {
        axios
            .get(path + "/numbers", { params: { notAssigned: true } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const createOverride = (
    customerId,
    numbers,
    overrideDestination,
    countryCode
) => {
    const path = `/temporary-override/${customerId}`;
    numbers = numbers.map(it=>it.replaceAll("[^0-9]", ""));
    return new Promise((resolve, reject) => {
        axios
            .post(path + "/create", {
                numbers: numbers,
                overrideDestination: overrideDestination,
                countryCode: countryCode,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const editOverride = (
    customerId,
    temporaryOverrideId,
    overrideDestination,
    countryCode
) => {
    const path = `/temporary-override/${customerId}/update`;
    overrideDestination = overrideDestination.replaceAll("[^0-9]", "");
    return new Promise((resolve, reject) => {
        axios
            .post(path, {
                temporaryOverrideId: temporaryOverrideId,
                overrideDestination: overrideDestination,
                countryCode: countryCode,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getTable = (customerId) => {
    return new Promise((resolve, reject) => {
        const path = `/temporary-override/${customerId}/numbers-table`;
        axios
            .get(path)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const removeRouting = (customerId, routeId) => {
    return new Promise((resolve, reject) => {
        const path = `/temporary-override/${customerId}/delete/${routeId}`;
        axios
            .post(path)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};
