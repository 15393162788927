import React, { useEffect, useState } from 'react'
import { PropTypes } from "prop-types";
import { DateTime } from 'luxon';
import { formatSecondsToTime } from '../../../utils/formatUtils';

const Countdown = props => {
    const [time, setTime] = useState(0);


    useEffect(() => {
        const interval = setInterval(() => {
            const newTime = Math.floor(DateTime.now().toSeconds()) - props.time;
            setTime(newTime);
        }, 1000);

        const newTime = Math.floor(DateTime.now().toSeconds()) - props.time;
        setTime(newTime);

        return () => {
            clearInterval(interval);
        }
    }
        , [props.time]
    )

    return (<span>{formatSecondsToTime(time)}</span>);
}

Countdown.propTypes = {
    time: PropTypes.number.isRequired
}

export default Countdown;