import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { DateTime } from "luxon";
import Card from "../cards/Card";
import axios from "../../../axios/axios";

const TopCards = (props) => {
    const { url, cards, timeInterval } = props;

    const [dayOfYear, setDayOfYear] = useState(
        DateTime.now().setZone("Europe/London").toFormat("o")
    );

    const [value, setValue] = React.useState();

    const dateTime = DateTime.now().setZone("Europe/London");
    const startDate = Math.floor(dateTime.startOf("day").toSeconds());
    const endDate = Math.floor(dateTime.endOf("day").toSeconds());

    const fetchData = async () => {
        try {
            const result = await axios.get(url, {
                params: {
                    start: startDate,
                    end: endDate,
                },
            });
            setValue(result.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const dataInterval = setInterval(fetchData, timeInterval);

        fetchData();

        const interval = setInterval(
            () => {
                const newDayOfYear = DateTime.now()
                    .setZone("Europe/London")
                    .toFormat("o");
                if (newDayOfYear !== dayOfYear) {
                    setDayOfYear(newDayOfYear);
                }
            },
            30000,
            30000
        );

        return () => {
            clearInterval(interval);
            clearInterval(dataInterval);
        };
    }, [url]);

    let cardsToShow = [];

    if (value) {
        cardsToShow = cards.map((card, index) => {
            return (
                <Card
                    key={index}
                    title={card.title}
                    text={value[card.field]}
                    tag={card.tag}
                    icon={card.icon}
                ></Card>
            );
        });
    }

    return (
        <div className="container-fluid">
            <div className="row">{cardsToShow}</div>
        </div>
    );
};

TopCards.propTypes = {
    timeInterval: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    cards: PropTypes.array.isRequired,
};

export default TopCards;
