import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../rdx/slices/customerSlice";
import {
    selectSelectedQueue,
    //selectQueuesInfo,
    setSelectedQueue,
    getQueuesForCustomerFromApi,
    selectCustomerQueues,
    getQueuesForCustomerFromApiAgentPage,
} from "../../../rdx/slices/queueSlice";
import _ from 'underscore'
import { string } from "prop-types";

const QueueSelector = (props) => {
    const dispatch = useDispatch();

    //const queuesForSelection = useSelector(selectQueuesInfo);
    const selectedQueue = useSelector(selectSelectedQueue);
    const selectedCustomer = useSelector(selectSelectedCustomer);
    const selectedCustomerQueues = useSelector(selectCustomerQueues, _.isEqual);

    //New customer selected => Get Queues.
    useEffect(() => {
        if (props.page == 'AGENTS') {
            dispatch(getQueuesForCustomerFromApiAgentPage(selectedCustomer.id));
        } else {
            dispatch(getQueuesForCustomerFromApi(selectedCustomer.id));
        }

    }, [selectedCustomer]);

    useEffect(() => {
        dispatch(setSelectedQueue(selectedCustomerQueues[0] ? selectedCustomerQueues[0].id : null))
    }, [selectedCustomerQueues])


    const handleSelectQueueDropDownEvent = (event) => {
        const value = event.target.value;
        dispatch(setSelectedQueue(value));
    };

    // const options = queuesForSelection.map((queueInfo, index) => (
    //     <option key={`queues-option-${index}`} value={queueInfo.queueId}>
    //         {queueInfo.queueName}
    //     </option>
    // ));

    const options = selectedCustomerQueues.map((queue, index) => (
        <option key={`queues-option-${index}`} value={queue.id}>
            {queue.name}
        </option>
    ));

    return (
        <Form>
            <Form.Group controlId="exampleForm.ControlSelect2">
                {/* <Form.Label>Viewing</Form.Label> */}
                <Form.Control
                    as="select"
                    onChange={(event) => handleSelectQueueDropDownEvent(event)}
                    value={selectedQueue ? selectedQueue.id : 0}
                >
                    {options}
                </Form.Control>
            </Form.Group>
        </Form>
    );
};

QueueSelector.propTypes = {
    page: string
  }

export default QueueSelector;
