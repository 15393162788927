import React from "react";
import NumberServiceUpdaterForm from "../components/nsu/NumberServiceUpdaterForm";


const NumberServiceUpdater = () => {
  return (
    <div className="main-content">
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row justify-content-between">
              <div
                className="col-12 align-self-center"
                //align="start"
              >
                <h6 className="header-pretitle">Admin</h6>
                <h1 className="header-title">Number Service Updater</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-lg-6">
          <NumberServiceUpdaterForm />
        </div>
      </div>
    </div>
  );
};
export default NumberServiceUpdater;
