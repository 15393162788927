import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "../../axios/axios";
import TableHeader from "../common/TableHeader";
import ChangePasswordPopUp from "./ChangePasswordPopUp";
import CreateUserPopUp from "./CreateUserPopUp";
import UserRow from "./UserRow";

const UsersTable = () => {


    const [showCreatePopUp, setShowCreatePopUp] = useState(false);
    const [showChangePasswordPopUp, setShowChangePasswordPopUp] = useState(null);
    const [data, setData] = React.useState([]);

    const [sortBy, setSortyBy] = React.useState({ sort: "id", dir: "DESC" });

    const [paging, setPaging] = React.useState({
        page: 0,
        isFirst: true,
        isLast: false,
        totalPages: 0,
    });

    useEffect(() => { }, []);

    function getData(page) {
        axios
            .get(
                `/admin/users?size=10&page=${page}&sort=${sortBy.sort},${sortBy.dir}`
            )
            .then((ok) => {
                const data = ok.data;
                setData(ok.data.response.content);
                setPaging({
                    ...paging,
                    page: ok.data.response.number,
                    isFirst: data.response.first,
                    isLast: data.response.last,
                    totalPages: data.response.totalPages,
                });
            });
    }

    useEffect(() => {

        if (sortBy.sort) {
            getData();
        }
    }, [sortBy]);

    useEffect(() => {
    }, [data]);

    useEffect(() => {
    }, [showCreatePopUp]);

    function onSortHeader(it) {
        if (sortBy.sort == it) {
            setSortyBy({ sort: it, dir: sortBy.dir == "ASC" ? "DESC" : "ASC" });
        } else {
            setSortyBy({ sort: it, dir: "DESC" });
        }
    }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => getData(pageNumber)}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        for (
            var i = Math.max(paging.page - 5, 0);
            i < Math.min(Math.max(paging.page - 5, 0) + 10, paging.totalPages);
            i++
        ) {
            const active = paging.page === i;
            render.push(formatPageElement(i, active));
        }

        return render;
    };

    return (
        <div>
            <div style={{ display: showCreatePopUp ? 'inline' : 'none' }}>
                <CreateUserPopUp closeCallback={() => setShowCreatePopUp(false)}></CreateUserPopUp>
            </div>
            <div style={{ display: showChangePasswordPopUp != null ? 'inline' : 'none' }}>
                {showChangePasswordPopUp!=null?<ChangePasswordPopUp userId={showChangePasswordPopUp.id} email={showChangePasswordPopUp.email} closeCallback={() => setShowChangePasswordPopUp(null)} />:'   '}
                
            </div>
            <div className="container-fluid tableContainer">
                <div className="card" style={{ overflow: "auto" }}>
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="card-header-title">
                                    Dashboard Users
                                </h3>

                            </div>
                            <div className="col d-flex" style={{ alignContent: 'end', justifyContent: 'end' }}>
                                <div onClick={() => {
                                    setShowCreatePopUp(true)
                                    console.log("SHOW ME")
                                }}>MAKE NEW</div></div>

                        </div>
                    </div>

                    <Table
                        className="table table-sm table-nowrap card-table"
                        responsive="sm"
                    >
                        <thead>
                            <tr>
                                <TableHeader
                                    id={"id"}
                                    name="ID"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"email"}
                                    name="EMAIL"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"enabled"}
                                    name="ENABLED"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"firstName"}
                                    name="FIRSTNAME"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"lastName"}
                                    name="LASTNAME"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"role.roleName"}
                                    name="ROLE"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"createdAt"}
                                    name="CREATED"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"_"}
                                    name="TOOLS"
                                    onSort={null}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                ? data.map((it) => (
                                    <UserRow key={`${it.id}`} data={it} onChangePasswordCallback={(u) => {
                                        console.log(u); 
                                        setShowChangePasswordPopUp(u)
                                    }} />
                                ))
                                : ""}
                        </tbody>
                    </Table>
                    <div className="card-footer d-flex justify-content-between">
                        {!paging.isFirst ? (
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-0 pe-4 border-end"
                                        onClick={() => getData(paging.page - 1)}
                                    >
                                        <i className="fe fe-arrow-left me-1"></i>{" "}
                                        Prev
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                            {pageElements()}
                        </ul>
                        {!paging.isLast ? (
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-4 pe-0 border-start"
                                        onClick={() => getData(paging.page + 1)}
                                    >
                                        Next{" "}
                                        <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default UsersTable;
