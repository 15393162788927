import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StatsPage from "../components/common/StatsPage";
import QueueSelector from "../components/home/selector/QueueSelector";
import {
    selectSelectedCustomer,
} from "../rdx/slices/customerSlice";
import {
    getQueuesForCustomerFromApiAgentPage,
    selectSelectedQueue,
} from "../rdx/slices/queueSlice";
import axios from "../axios/axios";
import AgentStatusCard from "../components/agents/AgentStatusCard";
import { sortByNameField } from "../utils/sortUtils";
// import _ from 'underscore'

const Agents = () => {
    const selectedCustomer = useSelector(selectSelectedCustomer);
    const selectedQueue = useSelector(selectSelectedQueue);
    const dispatch = useDispatch();

    const [agents, setAgents] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    function getData() {
        setLoading(true);
        axios
            .get(
                `/agents/customer/${selectedCustomer.id}/queue/${selectedQueue.id}`
            )
            .then((ok) => {
                setAgents(ok.data.response ? ok.data.response : []);
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            });
    }

    sortByNameField(agents);

    const agentCards = agents.map((it) => (
        <AgentStatusCard
            key={it.name}
            name={it.name}
            status={it.status}
            directLine={it.directNumber}
        ></AgentStatusCard>
    ));

    useEffect(() => {
        dispatch(getQueuesForCustomerFromApiAgentPage(selectedCustomer.id));
    }, []);

    useEffect(() => {
        if (selectedCustomer) {
            dispatch(getQueuesForCustomerFromApiAgentPage(selectedCustomer.id));
        }
    }, [selectedCustomer]);

    useEffect(() => {
        let getAgentStatus = null;
        if (selectedQueue) {
            getData();
            getAgentStatus = setInterval(() => {
                getData();
            }, 10000);
        }
        return () => {
            clearInterval(getAgentStatus);
        };
    }, [selectedQueue]);

    const normalStyle = {
        opacity: 1,
        transition: "0.2s",
    };

    const transformedStyle = {
        ...normalStyle,
        opacity: 0.6
    };

    let render = selectedCustomer ? (
        <div>
            <div className="container-fluid"></div>
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-4 col-sm-12 align-self-center">
                        <h3> Select Queue</h3>
                        <QueueSelector page='AGENTS' />
                    </div>
                    <div className="col align-self-center"></div>
                </div>
                {
                    <div
                        className="row"
                        style={loading ? transformedStyle : normalStyle}
                    >
                        {agentCards}
                    </div>
                }
            </div>
        </div>
    ) : null;

    return <StatsPage title="Agents" pretitle="Overview" render={render} />;
};

export default Agents;
