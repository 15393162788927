import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import { selectSelectedCompBrand } from "../../rdx/slices/compSlice";
import TableHeader from "../common/TableHeader";
import CompetitionEntryRow from "./CompetitionEntryRow";

const CompetitionsEntryTable = () => {
    const [data, setData] = React.useState([]);

    const [sortBy, setSortyBy] = React.useState({ sort: "id", dir: "DESC" });

    const [paging, setPaging] = React.useState({
        page: 0,
        isFirst: true,
        isLast: false,
        totalPages: 0,
    });

    const selectedBrand = useSelector(selectSelectedCompBrand);

    useEffect(() => {}, []);

    function getData(page) {

        if(selectedBrand){
        axios
            .get(
                `/competitions/brand/${
                    selectedBrand.id
                }/entries/table?size=10&page=${page ?? 0}&sort=${sortBy.sort},${
                    sortBy.dir
                }`
            )
            .then((ok) => {
                
                const data = ok.data.response.content;
                setData(data);
                setPaging({
                    ...paging,
                    page: ok.data.response.number,
                    isFirst: ok.data.response.first,
                    isLast: ok.data.response.last,
                    totalPages: ok.data.response.totalPages,
                });
            })
            .catch((err) => {
                console.error(err);
            });
        }
    }

    useEffect(() => {
        if (sortBy.sort) {
            getData();
        }
    }, [sortBy]);
    useEffect(() => {
        getData();
    }, [selectedBrand]);
    useEffect(() => {}, [data]);

    function onSortHeader(it) {
        if (sortBy.sort == it) {
            setSortyBy({ sort: it, dir: sortBy.dir == "ASC" ? "DESC" : "ASC" });
        } else {
            setSortyBy({ sort: it, dir: "DESC" });
        }
    }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => getData(pageNumber)}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        for (
            var i = Math.max(paging.page - 5, 0);
            i < Math.min(Math.max(paging.page - 5, 0) + 10, paging.totalPages);
            i++
        ) {
            const active = paging.page === i;
            render.push(formatPageElement(i, active));
        }

        return render;
    };

    return (
        <div>
            <div className="container-fluid tableContainer">
                <div className="card" style={{ overflow: "auto" }}>
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="card-header-title">
                                    Competition Entries
                                </h3>
                            </div>
                        </div>
                    </div>

                    <Table
                        className="table table-sm table-nowrap card-table"
                        responsive="sm"
                    >
                        <thead>
                            <tr>
                                <TableHeader
                                    id={"id"}
                                    name="ENTRY ID"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"competitionName"}
                                    name="COMPETITION"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"compCompetitionClient.clientName"}
                                    name="CLIENT"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"stype"}
                                    name="ENTRY TYPE"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"callid"}
                                    name="CALL ID"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"dnumber"}
                                    name="DESTINATION"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"snumber"}
                                    name="CUSTOMER"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"time"}
                                    name="TIME"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"competitionWindow.startTime"}
                                    name="STARTED"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"competitionWindow.endTime"}
                                    name="END TIME"
                                    onSort={onSortHeader}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((it) => (
                                <CompetitionEntryRow
                                    key={`${it.competitionId}-${it.competitionWindowId}-${it.clientId}`}
                                    data={it}
                                />
                            ))}
                        </tbody>
                    </Table>
                    <div className="card-footer d-flex justify-content-between">
                        {!paging.isFirst ? (
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-0 pe-4 border-end"
                                        onClick={() => getData(paging.page - 1)}
                                    >
                                        <i className="fe fe-arrow-left me-1"></i>{" "}
                                        Prev
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                            {pageElements()}
                        </ul>
                        {!paging.isLast ? (
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-4 pe-0 border-start"
                                        onClick={() => getData(paging.page + 1)}
                                    >
                                        Next{" "}
                                        <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompetitionsEntryTable;
