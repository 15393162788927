import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { sortByObjectProperty } from "../../utils/sortUtils";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import {
    getClientsFromAPI,
    selectCompClients,
    selectSelectedCompBrand,
    selectSelectedCompClient,
    setSelectedClient,
} from "../../rdx/slices/compSlice";

const CompetitionClientSelector = () => {
    const selectedBrand = useSelector(selectSelectedCompBrand, _.isEqual);
    const clients = useSelector(selectCompClients);
    const selectedClient = useSelector(selectSelectedCompClient);
    const dispatch = useDispatch();
    const options = [];

    useEffect(() => {}, []);

    useEffect(() => {
        if (selectedBrand !== null) {
            dispatch(getClientsFromAPI(selectedBrand.id));
        }
    }, [selectedBrand]);

    const formatOption = (client) => {
        return (
            <option
                key={client.clientId}
                value={client.clientId}
                id={client.clientId}
            >
                {client.clientName}
            </option>
        );
    };

    const copy = [...clients];
    sortByObjectProperty("clientName", "ASC", copy);

    copy.forEach((client) => {
        options.push(formatOption(client));
    });

    const handleSelection = (event) => {

        if (event.target.value !== null) {
            dispatch(setSelectedClient(event.target.value));
        }
    };

    return (
        <Form>
            <Form.Group controlId="selecty">
                {/* <Form.Label>Viewing</Form.Label> */}
                <Form.Control
                    as="select"
                    onChange={(event) => handleSelection(event)}
                    value={selectedClient ? selectedClient.clientId : 0}
                >
                    {options}
                </Form.Control>
            </Form.Group>
        </Form>
    );
};

export default CompetitionClientSelector;
